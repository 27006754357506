import React, { useState } from "react";
import { getIn } from "formik";
import { Input, FormFeedback, FormGroup, Label, Col } from "reactstrap";
import "./CurrencyField.scss";
import { putSpacesEvery000 } from "../../utils";

const CurrencyField = ({
  form,
  field,
  precision = 2,
  rowLayout = false,
  currency = "€",
  formGroupClassName = "",
  label = "",
  labelClassName = "",
  className = "",
  forceDisplayValue = "",
  ...props
}) => {
  const value = parseFloat(field.value);
  const textContent = forceDisplayValue
    ? putSpacesEvery000(parseFloat(forceDisplayValue).toFixed(2))
    : field.value === ""
    ? ""
    : isNaN(value)
    ? "??"
    : putSpacesEvery000(value.toFixed(precision));

  const touch = getIn(form.touched, field.name);
  const error = getIn(form.errors, field.name);

  const [hasFocus, setFocus] = useState(false);

  let inputElement = (
    <span className="currency-field-wrapper">
      <Input
        {...props}
        name={field.name}
        value={hasFocus ? field.value : textContent}
        onChange={(e) => {
          let v = e.target.value;
          const dotPos = v.indexOf(".");
          if (dotPos < 0 && v.length <= 13) {
            form.setFieldValue(field.name, v);
          } else if (
            v.substring(0, dotPos).length <= 13 &&
            v.substring(dotPos + 1).length <= 2
          ) {
            form.setFieldValue(field.name, v);
          }
        }}
        onFocus={(e) => {
          setFocus(true);
          field.onFocus && field.onFocus(e);
        }}
        onBlur={(e) => {
          setFocus(false);
          field.onBlur && field.onBlur(e);
        }}
        type={hasFocus ? "number" : "text"}
        className={`${className} input-currency`}
        invalid={touch && !!error}
      />
      <span className="input-currency-unit">€</span>
    </span>
  );

  let feedbackElement = touch && error && (
    <FormFeedback className="d-block">{error}</FormFeedback>
  );

  return (
    <FormGroup row={rowLayout} className={formGroupClassName}>
      {label && (
        <Label
          sm={rowLayout ? 4 : undefined}
          className={`text-left ${labelClassName}`}
        >
          {label}
        </Label>
      )}
      {rowLayout && (
        <Col sm={8}>
          {inputElement}
          {feedbackElement}
        </Col>
      )}
      {!rowLayout && (
        <React.Fragment>
          {inputElement}
          {feedbackElement}
        </React.Fragment>
      )}
    </FormGroup>
  );
};

export default CurrencyField;
