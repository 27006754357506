import React, { useState } from "react"
import { Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap"
import { FaTrashAlt } from "react-icons/fa"

const DeleteAziendaModal = ({ azienda, onConfirm, onCancel }) => {
  const [deleteName, setDeleteName] = useState("")
  const [error, setError] = useState("")

  const handleSubmit = e => {
    e.preventDefault()
    if (deleteName === azienda.ragione_sociale) {
      onConfirm()
    } else {
      setError("Nome errato, impossibile procedere")
    }
    return false
  }

  if (!azienda) {
    return null
  }

  return (
    <Modal size="sm" isOpen={true} toggle={onCancel} unmountOnClose={false}>
      <ModalHeader toggle={onCancel}>Elimina società</ModalHeader>
      <ModalBody>
        <p>
          Per confermare l'eliminazione, digita il nome della società da eliminare
        </p>
        <p style={{ userSelect: "none" }}>
          <b>{azienda.ragione_sociale}</b>
        </p>
        <form onSubmit={handleSubmit}>
          <input
            className="form-control form-control-sm"
            value={deleteName}
            onChange={e => setDeleteName(e.target.value)}
          />
        </form>
        <p className="text-danger mt-1 mb-0 mx-0">{error}</p>
      </ModalBody>
      <ModalFooter>
        <div className="d-flex flex-row justify-content-between align-items-center w-100">
          <button className="btn btn-sm btn-light" onClick={onCancel}>Annulla</button>
          <button
            className="btn btn-sm btn-danger"
            onClick={handleSubmit}
            disabled={deleteName !== azienda.ragione_sociale}
          >
            <span className="d-inline-flex align-items-center">
              <FaTrashAlt className="mr-1" />
              <span>Elimina azienda</span>
            </span>
          </button>
        </div>
      </ModalFooter>
    </Modal>
  )
}

export default DeleteAziendaModal