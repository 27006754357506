import React from "react"
import { useRunRj, deps } from "react-rocketjump"
import { AnnotazioneIndicatoriState } from "../../localstate/bilanci"
import ReportHeader from "./Header"
import IndicatorCard from "./IndicatorCard"
import sortBy from "lodash/sortBy"
import get from "lodash/get"

const MixedIndicatorsPage = props => {
  const { bilancio, schemaIndicatori, indicatoriLeve, indicatoriFcfo, indicatoriBep, indicatorsStatics, thresholds } = props
  const [{ data: notesLeve }] = useRunRj(AnnotazioneIndicatoriState, [deps.maybeGet(bilancio, "id"), "LEVE"])
  const [{ data: notesFcfo }] = useRunRj(AnnotazioneIndicatoriState, [deps.maybeGet(bilancio, "id"), "FCFO"])
  const [{ data: notesBep }] = useRunRj(AnnotazioneIndicatoriState, [deps.maybeGet(bilancio, "id"), "BEP"])

  if (!indicatoriLeve || !indicatoriFcfo || !indicatoriBep) {
    return null
  }

  const LeveIndicators = schemaIndicatori["LEVE"].indicators
  const FcfoIndicators = schemaIndicatori["FCFO"].indicators
  const BepIndicators = schemaIndicatori["BEP"].indicators

  const notesLeveContent = get(notesLeve, "note", null)
  const notesFcfoContent = get(notesFcfo, "note", null)
  const notesBepContent = get(notesBep, "note", null)

  return (
    <>
      <div className="page indicator-page ida-page">
        <ReportHeader {...props} />
        <h1>ALTRI INDICI</h1>
        <h2>Leve Aziendali</h2>
        <div className="under_title">
          <ul>
            <li>
              <p>Leva operativa = Margine di contribuzione (ricavi – costi variabili) / reddito operativo</p>
              <p>Se il valore è elevato, significa che è alta l’incidenza dei costi fissi, ciò espone l’azienda ad alti rischi in caso di una riduzione anche piccola del fatturato</p>
            </li>
            <li>
              <p>Leva produttiva = Costo del lavoro / Valore aggiunto</p>
              <p>Esprime il tasso di assorbimento del valore aggiunto da parte del costo del lavoro, maggiore è tale indice maggiore è il rischio di minore efficienza industriale ed organizzativa, che non lascia spazio di remunerazione agli altri fattori produttivi (capitale proprio e di capitale di terzi)</p>
            </li>
            <li>
              <p>Rapporto di indebitamento= Attivo / capitale netto</p>
              <p>Esprime il grado di rischiosità finanziaria, dell’impresa elevati rapporti sottendono una forte dipendenza delle fonti finanziarie da realtà terze all’impresa.</p>
            </li>
          </ul>
        </div>
        <div className="grid-1x3">
          {sortBy(indicatoriLeve, "codice").filter(i => !!LeveIndicators[i.codice]).map(indicatorValue => (
            <IndicatorCard
              indicatorsStatics={indicatorsStatics}
              key={indicatorValue.codice}
              thresholds={thresholds}
              indicatorName={indicatorValue.codice}
              indicatorDescription={LeveIndicators[indicatorValue.codice]}
              indicatorValue={indicatorValue}
            />
          ))}
        </div>
      </div>
      <div className="page indicator-page ida-page">
        <ReportHeader {...props} />
        <h1>ALTRI INDICI</h1>
        <h2>Free Cash Flow Operativo e Tasso di Sviluppo Sostenibile</h2>
        <div className="under_title">
          <ul>
            <li>CASH FLOW = Autofinanziamento operativo +/- variazione del capitale circolante netto +/- assorbimento degli investimenti in immobilizzazioni (Capex)</li>
            <li>
              <p>
                TASSO DI SVILUPPO SOSTENIBILE<br />
                <span className="pre">TSS = [ROI + (ROI - I) * D / E] * (1 - tax) + (ΔPatrimonio Netto / Patrimonio Netto)</span><br />
                Ove<br />
                <span className="pre">ROI = EBIT / Capitale Investito</span> ove <span className="pre">EBIT = Reddito Operativo</span><br />
                <span className="pre">I = OF / Debiti Finanziari</span> ove <span className="pre">OF = Oneri Finanziari</span><br />
              </p>
            </li>
            <li>
              <p>
                TASSO DI SVILUPPO PROGRAMMATO<br />
                <span className="pre">TSP = ΔCapitale Invstito / Capitale Investito</span>
              </p>
            </li>
            <li>
              Se TSS > TSP allora si tende ad avere un equilibrio finanziario<br />
              Se TSS {"<"} TSP allora si tende ad avere uno squilibrio finanziario
          </li>
          </ul>
        </div>
        <div className="grid-1x3">
          {sortBy(indicatoriFcfo, "codice").filter(i => !!FcfoIndicators[i.codice]).map(indicatorValue => (
            <IndicatorCard
              indicatorsStatics={indicatorsStatics}
              key={indicatorValue.codice}
              thresholds={thresholds}
              indicatorName={indicatorValue.codice}
              indicatorDescription={FcfoIndicators[indicatorValue.codice]}
              indicatorValue={indicatorValue}
            />
          ))}
        </div>
        <h2>Break Even Point</h2>
        <div className="under_title">
          <p>
            Il <u>Margine di sicurezza</u> è pari alla differenza tra Ricavi di vendita - B.E.P.,
            quindi rappresenta quel livello di vendite in cui l’azienda ha una marginalità positiva
            avendo coperti tutti i costi.
        </p>
          <p>
            Il B.E.P. (break even point) è pari al punto di equilibrio tra i ricavi di vendita e i Costi Totali = V-CT = 0
        </p>
          <p>
            In tal caso V = Ricavo di Equilibrio
        </p>
        </div>
        <div className="grid-1x3">
          {sortBy(indicatoriBep, "codice").filter(i => !!BepIndicators[i.codice]).map(indicatorValue => (
            <IndicatorCard
              indicatorsStatics={indicatorsStatics}
              key={indicatorValue.codice}
              thresholds={thresholds}
              indicatorName={indicatorValue.codice}
              indicatorDescription={BepIndicators[indicatorValue.codice]}
              indicatorValue={indicatorValue}
              noScore={indicatorValue.codice.startsWith("__")}
            />
          ))}
        </div>
        {(notesLeveContent || notesFcfoContent || notesBepContent) && (
          <div className="page indicator-page ida-page">
            <ReportHeader {...props} />
            <h1>ALTRI INDICI</h1>
            <h2>Annotazioni</h2>
            {notesLeveContent && (
              <>
                <p className="kind-of-title"><b>Leve aziendali</b></p>
                <p className="note">{notesLeveContent}</p>
              </>
            )}
            {notesFcfoContent && (
              <>
                <p className="kind-of-title"><b>Free Cash Flow Operativo e Tasso di Sviluppo Sostenibile</b></p>
                <p className="note">{notesFcfoContent}</p>
              </>
            )}
            {notesBepContent && (
              <>
                <p className="kind-of-title"><b>Break Even Point</b></p>
                <p className="note">{notesBepContent}</p>
              </>
            )}
          </div>
        )}

      </div>
    </>
  )

}

export default MixedIndicatorsPage