import React from "react"
import LogoGiuffre from "./assets/logo_giuffre.png"
import "./FirstPage.scss"
import { format } from "date-fns"
import flatten from "lodash/flatten"
import map from "lodash/map"
import concat from "lodash/concat"
import keyBy from "lodash/keyBy"
import { scaleLinear } from "d3-scale"
import { evalRiskColor } from "../../utils"

const RadarChart = props => {
  const {
    indicatoriIda,
    indicatoriIdap,
    indicatoriIcas,
    indicatoriLeve,
    indicatoriFcfo,
    indicatoriBep,
    indicatoriIspi,
    schemaIndicatori
  } = props

  const allIndicators = concat(
    [],
    indicatoriIda,
    indicatoriIdap,
    indicatoriIcas,
    indicatoriLeve,
    indicatoriFcfo,
    indicatoriBep,
    indicatoriIspi,
  )

  const all = [
    indicatoriIda,
    indicatoriIdap,
    indicatoriIcas,
    indicatoriLeve,
    indicatoriFcfo,
    indicatoriBep,
    indicatoriIspi,
  ]

  if (all.some(v => !v)) {
    return null
  }

  const indicatorsIndex = keyBy(
    allIndicators.filter(i => !i.codice.startsWith("__")),
    i => `${i.gruppo}:${i.codice}`
  )

  const data = flatten(map(schemaIndicatori, (groupDescription, groupName) => {
    return map(groupDescription.indicators, (indicatorDescription, indicatorCode) => {
      const key = `${groupName}:${indicatorCode}`
      if (indicatorsIndex[key] === undefined) {
        return null
      }
      return {
        label: indicatorCode,
        value: indicatorsIndex[key]
      }
    }).filter(i => i !== null)
  }))

  const angleScale = scaleLinear()
    .domain([0, data.length])
    .range([Math.PI / 2, 2.5 * Math.PI])

  const radialScale = scaleLinear()
    .domain([0, 1])
    .range([25, 150])

  const pathPoints = data.map(({ value }, i) => {
    const theta = angleScale(i)
    const radius = radialScale(parseFloat(value.punteggio))
    return [radius * Math.cos(theta), radius * Math.sin(-theta)].join(" ")
  })

  const d = "M " + pathPoints.concat(pathPoints[0]).join(" L ")

  return (
    <svg className="w-100 h-100" viewBox="-250 -250 500 500">
      <path
        d={d}
        fill="#87CEEB"
      />
      <circle cx={0} cy={0} r={25} fill="transparent" stroke="#68d391" strokeWidth={2} />
      <circle cx={0} cy={0} r={50} fill="transparent" stroke="#68d391" strokeWidth={2} />
      <circle cx={0} cy={0} r={75} fill="transparent" stroke="#f6e05e" strokeWidth={2} />
      <circle cx={0} cy={0} r={100} fill="transparent" stroke="#f6e05e" strokeWidth={2} />
      <circle cx={0} cy={0} r={125} fill="transparent" stroke="#e53e3e" strokeWidth={2} />
      <circle cx={0} cy={0} r={150} fill="transparent" stroke="#e53e3e" strokeWidth={2} />
      {data.map(({ label }, i) => {
        const alpha = angleScale(i)
        const alphaDeg = -alpha / (2 * Math.PI) * 360
        const cos = Math.round(Math.cos(alpha) * 10000) / 10000
        const textAnchor = cos <= 0 ? "end" : "start"
        const textRot = cos <= 0 ? 180 : 0

        return (
          <React.Fragment key={i}>
            <line
              x1={0}
              y1={0}
              x2={Math.cos(angleScale(i)) * 170}
              y2={-Math.sin(angleScale(i)) * 170}
              stroke="#666666"
              strokeWidth={1.5}
            />
            <g transform={`rotate(${alphaDeg.toFixed(2)} 0 0)`}>
              <g transform={`rotate(${textRot} 180 0)`}>
                <text x={180} y={0} fontSize={12} textAnchor={textAnchor} alignmentBaseline="middle">{label}</text>
              </g>
            </g>
          </React.Fragment>
        )
      })}
      <text x={0} y={-25} fill="#333333" fontSize={12} dx={3} dy={-3}>0%</text>
      <text x={0} y={-50} fill="#333333" fontSize={12} dx={3} dy={-3}>20%</text>
      <text x={0} y={-75} fill="#333333" fontSize={12} dx={3} dy={-3}>40%</text>
      <text x={0} y={-100} fill="#333333" fontSize={12} dx={3} dy={-3}>60%</text>
      <text x={0} y={-125} fill="#333333" fontSize={12} dx={3} dy={-3}>80%</text>
      <text x={0} y={-150} fill="#333333" fontSize={12} dx={3} dy={-3}>100%</text>
    </svg>
  )


}


const OverallScore = props => {
  if (props.bilancio.overall_risk === null) {
    return null
  }
  const scoreClass = evalRiskColor(props.bilancio.overall_risk)

  return (
    <div className={`box ${scoreClass}`}>
      {Math.round(props.bilancio.overall_risk * 100) + "%"}
    </div>
  )


}

const FirstPage = props => {
  const { azienda, bilancio, commercialista, config, className } = props
  return (
    <div className={`page report-first-page ${className}`}>
      <div className="logo-giuffre">
        <img src={LogoGiuffre} alt="" />
      </div>
      <div className="logo-azienda">
        <img src={azienda.logo} alt="" />
      </div>
      <div className="header">
        <h1>REPORT</h1>
        <h2>MONITORAGGIO CRISI D'IMPRESA</h2>
        <p>art.13 comma 2 codice della Crisi e dell'Insolvenza</p>
        {commercialista.logo_studio && (
          <img src={commercialista.logo_studio} alt="" />
        )}
      </div>
      <div className="author">
        <p>
          <b>Elaborato da:</b>
          <span className="ml-5">{commercialista.nome_studio}</span>
        </p>
      </div>
      <div className="info-bilancio">
        <table>
          <tbody>
            <tr>
              <td>
                <h1>Azienda</h1>
              </td>
              <td>{azienda.ragione_sociale}</td>
            </tr>
            <tr>
              <td>
                <h2>Data Bilancio</h2>
              </td>
              <td>{format(new Date(bilancio.data_bilancio), "dd/MM/yyyy")}</td>
            </tr>
            <tr>
              <td>
              </td>
              <td>
                <small>
                  <i>
                    {bilancio.descrizione}
                  </i>
                </small>
              </td>
            </tr>
            <tr>
              <td>
                <h2>Settore</h2>
              </td>
              <td>{config.settori.find(settore => settore[0] === azienda.settore)[1]}</td>
            </tr>
          </tbody>
        </table>
      </div>
      <div className="rischio">
        <h2>GRADO SINTETICO DI RISCHIO</h2>
        <OverallScore {...props} />
      </div>
      <div className="chart">
        <RadarChart {...props} />
      </div>
    </div>
  )
}

export default FirstPage