import React from "react"
import { Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap"

const DemoDeleteAziendaModal = ({ onCancel }) => {
  return (
    <Modal size="md" isOpen={true} toggle={onCancel} unmountOnClose={false}>
      <ModalHeader toggle={onCancel}>Upgrade richiesto</ModalHeader>
      <ModalBody>
        <p>
          Sei titolare di una licenza demo di 4 Check Impresa. Acquista una
          licenza per eliminare questa azienda.
        </p>
      </ModalBody>
      <ModalFooter>
        <div className="d-flex flex-row justify-content-between align-items-center w-100">
          <button className="btn btn-sm btn-light" onClick={onCancel}>
            Ho capito
          </button>
        </div>
      </ModalFooter>
    </Modal>
  )
}

export default DemoDeleteAziendaModal
