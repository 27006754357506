import React from "react"
import get from "lodash/get"
import sortBy from "lodash/sortBy"
import { scaleLinear } from "d3-scale"
import { CARD_CLASSES } from "../../constants"
import ReactResizeDetector from "react-resize-detector"
import IndicatorValue from "../IndicatorValue"

const IndicatorBar = ({ indicatorCode, indicatorDescription, computedValue, thresholds, enableTexts = true, ...props }) => {
  const indicatorThreshold = get(thresholds, indicatorCode, [])
  const v = computedValue !== null ? parseFloat(computedValue) : null

  if (indicatorThreshold.length === 0 || v === null || isNaN(v)) {
    return null
  }

  const absoluteMin = Math.min(...indicatorThreshold.map(item => get(item, "min", -Infinity)))
  const absoluteMax = Math.max(...indicatorThreshold.map(item => get(item, "max", Infinity)))

  const finiteMin = Math.min(...indicatorThreshold.map(item => get(item, "min", -Infinity)).filter(x => isFinite(x)))
  const finiteMax = Math.max(...indicatorThreshold.map(item => get(item, "max", -Infinity)).filter(x => isFinite(x)))

  const finiteSize = Math.abs(finiteMax - finiteMin)

  const min =
    isFinite(absoluteMin)
      ? absoluteMin
      : finiteMin === finiteMax
        ? indicatorDescription.unit === "€"
          ? -100000
          : -5
        : finiteMin - 0.4 * finiteSize

  const max =
    isFinite(absoluteMax)
      ? absoluteMax
      : finiteMin === finiteMax
        ? indicatorDescription.unit === "€"
          ? 100000
          : 5
        : finiteMax + 0.4 * finiteSize

  const scale = scaleLinear()
    .domain([min, max])
    .clamp(true)


  const getColor = score => {
    return CARD_CLASSES.find(item => item.from <= score && score < item.to).color
  }

  return (
    <div {...props} style={{ background: "rgba(255, 255, 255, 0.7)", padding: "3px", borderRadius: 3 }}>
      <div style={{height: enableTexts ? 30 : 22, overflow: "hidden", position: "relative" }}>
        <ReactResizeDetector handleWidth>
          {({ width }) => {
            if (width === 0 || width === undefined) {
              return <svg />
            } else {
              const xScale = scale.copy().range([4, width - 4])
              const x = xScale(v)
              return (
                <svg style={{ width: "100%", height: enableTexts ? 30 : 22, position: "absolute", zIndex: 1 }} viewBox={`0 0 ${width} ${enableTexts ? 30 : 22}`}>
                  {sortBy(indicatorThreshold, i => parseFloat(get(i, "min", -Infinity))).map((scoreRule, i, lst) => {
                    const myMin = get(scoreRule, "min", -Infinity)
                    const myMax = get(scoreRule, "max", +Infinity)
                    return (
                      <React.Fragment key={i}>
                        {enableTexts && isFinite(myMin) && (i === 0) && (
                          <text x={xScale(myMin)} y={30} textAnchor={i === 0 ? "start" : "middle"} fontSize={8}>
                            <IndicatorValue indicatorDescription={indicatorDescription} rawValue={myMin} />
                          </text>
                        )}
                        {enableTexts && isFinite(myMax) && (i+1 === lst.length || lst[i+1].score !== scoreRule.score) && (
                          <text
                            x={xScale(myMax)}
                            y={30}
                            textAnchor={i === lst.length - 1 ? "end" : "middle"} 
                            fontSize={8}
                          >
                            <IndicatorValue indicatorDescription={indicatorDescription} rawValue={myMax} />
                          </text>
                        )}
                        <rect
                          x={xScale(myMin)}
                          y={5}
                          width={xScale(myMax) - xScale(myMin)}
                          height={15}
                          fill={getColor(scoreRule.score)}
                          stroke={getColor(scoreRule.score)}
                        />
                      </React.Fragment>
                    )
                  })}
                  <path
                    d={`M ${x} 4 L ${x - 4} 0 L ${x + 4} 0 L ${x} 4`}
                    fill="#000000"
                    strokeLinecap="round"
                    stroke="#000000"
                  />
                </svg>
              )
            }
          }}
        </ReactResizeDetector>
      </div>
    </div>
  )

}

export default IndicatorBar