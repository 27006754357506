export const putSpacesEvery000 = str => {
  let prefix = ""
  if (str.startsWith("-")) {
    str = str.substr(1)
    prefix = "-"
  }
  const dotPos = str.indexOf(".")
  let i = dotPos % 3
  let out = ""
  while (i < dotPos) {
    out += str.substring(Math.max(i - 3, 0), i) + " "
    i += 3
  }
  out += str.substring(dotPos - 3)
  return prefix + out.trim()
}

export const evalRiskColor = riskLevel => {
  if (riskLevel <= 0.4) {
    return "indicator-good"
  }
  else if (riskLevel <= 0.8) {
    return "indicator-warn"
  }
  else {
    return "indicator-worst"
  }
}