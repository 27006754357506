import React, { useState, useRef, useEffect, useCallback, useContext } from "react"
import { useRunRj, deps, useRj } from "react-rocketjump"
import { IndicatoriBilancioState, SchemaIndicatoriState, ValoriRettificaIndicatoriBilancioState, IndicatoriLiveState, AnnotazioneIndicatoriState } from "../../localstate/bilanci"
import { Link, Switch, Route, Redirect } from "react-router-dom"
import map from "lodash/map"
import find from "lodash/find"
import mapValues from "lodash/mapValues"
import assign from "lodash/assign"
import get from "lodash/get"
import omit from "lodash/omit"
import pick from "lodash/pick"
import isEqual from "lodash/isEqual"
import { Spinner, Modal, ModalHeader, ModalBody } from "reactstrap"
import { PercentField, CheckboxFieldOptions } from "../../components/Fields"
import { Formik, Field, useFormikContext } from "formik"
import usePrevious from "magik-react-hooks/usePrevious"
import { putSpacesEvery000 } from "../../utils"
import CurrencyField from "../../components/Fields/CurrencyField"
import NotesModal from "./NotesModal"
import { CARD_CLASSES } from "../../constants"
import IndicatorBar from "../../components/IndicatorBar/IndicatorBar"
import { ThresholdsState } from "../../components/IndicatorBar/localstate"
import { AziendaContext } from "../../providers"
import { FaInfoCircle } from "react-icons/fa"
import ThresholdsTable from "../../components/ThresholdsTable"
import { IndicatorStaticsState } from "../../localstate/indicatorStatics"
import keyBy from "lodash/keyBy"
import { useAuthUser } from "use-eazy-auth"

const Tab = ({ url, children, disabled }) => {
  if (disabled) {
    return null
  }
  return (
    <Switch>
      <Route path={url} render={() => (
        <li className="nav-item">
          <Link className="nav-link active" to={url}>{children}</Link>
        </li>
      )}>
      </Route>
      <Route render={() => (
        <li className="nav-item">
          <Link className={`nav-link ${disabled ? "disabled" : ""}`} to={url}>{children}</Link>
        </li>
      )}>
      </Route>
    </Switch>
  )
}

const formatIndicatorValue = (indicatorDescription, rawValue) => {
  if (rawValue === null || rawValue === undefined) {
    return "-"
  }
  if (indicatorDescription.unit === "€") {
    let str = parseFloat(rawValue).toFixed(2)
    return putSpacesEvery000(str) + " €"
  }
  else if (indicatorDescription.unit === "%") {
    return (parseFloat(rawValue) * 100).toFixed(2) + " %"
  } else {
    return parseFloat(rawValue).toFixed(2)
  }
}

const Header = ({ renderHeader, schema, match, bilancio, aggiornaIndicatori, shouldSave, isSaving, readOnly }) => {
  return (
    <>
      {aggiornaIndicatori && renderHeader({
        items: (
          <>
            <div />
            <button
              type="button"
              className="btn btn-sm btn-success"
              onClick={aggiornaIndicatori}
              disabled={readOnly || isSaving}
            >
              Salva
            {shouldSave && !isSaving && " *"}
              {isSaving && <Spinner size='sm' className='ml-1' />}
            </button>
          </>
        )
      })}
      <div className="m-2">
        <ul className="nav nav-tabs">
          {schema && Object.keys(schema).map(groupCode => (
            <Tab
              url={`${match.url}/${groupCode}`}
              disabled={!bilancio || (bilancio.bilancio_chiusura && !schema[groupCode]["fine_esercizio"])}
              key={groupCode}
            >
              {schema[groupCode].name}
            </Tab>
          ))}
        </ul>
      </div>
    </>
  )
}

const AuthLink = ({ href, children, ...props }) => {
  const { user } = useAuthUser()
  const ticket = get(user, "dati_licenza.last_ticket_id", "")
  let hrefWithTicket = href

  try {
    const url = new URL(hrefWithTicket)
    if (url.search.length <= 1) {
      url.search = `?ticket=${ticket}`
    } else {
      url.search = url.search + `&ticket=${ticket}`
    }
    hrefWithTicket = url.href
  } catch (e) {
    
  }
  
  return <a href={hrefWithTicket} {...props}>{children}</a>
}

const IndicatorCard = ({
  gruppo,
  codiceIndicatore,
  descrizioneIndicatore,
  indicatore,
  readOnly,
  thresholds,
  indicatorsStatics,
}) => {
  const value = get(indicatore, "valore")
  const score = get(indicatore, "punteggio")
  const etichetta = get(indicatore, "etichetta_punteggio", "")
  const formattedValue = value !== null ? formatIndicatorValue(descrizioneIndicatore, value) : '-'
  const scoreClass = score === null
    ? 'indicator-no-value'
    : get(CARD_CLASSES.find(item => item.from <= score && score < item.to), "className")

  const [isModalOpen, setModalOpen] = useState(false)

  const statics = get(indicatorsStatics, codiceIndicatore, {})

  const toggleModal = useCallback(() => {
    setModalOpen(prev => !prev)
  }, [])

  return (
    <>
      <div className={`card indicator-card position-relative ${scoreClass}`}>
        <FaInfoCircle
          className="position-absolute pointer"
          style={{ top: 5, right: 5 }}
          onClick={() => toggleModal()}
        />
        <p className="indicator-card indicator-name">{get(statics, "descrizione_breve", descrizioneIndicatore.name)}</p>
        <p className="indicator-card indicator-value">{formattedValue}</p>
        <div className="small text-center mb-1">{etichetta}</div>
        <IndicatorBar
          enableTexts={false}
          indicatorDescription={descrizioneIndicatore}
          indicatorCode={codiceIndicatore}
          computedValue={value}
          thresholds={thresholds}
          className="mb-1"
        />
        <div className="indicator-card space" />
        <div>
          {Object.keys(descrizioneIndicatore.inputs).map(inputName => (
            <div className="indicator-card indicator-input" key={inputName}>
              <span className="indicator-input-name">{descrizioneIndicatore.inputs[inputName].name}</span>
              {descrizioneIndicatore.inputs[inputName].type === "€" && (
                <span onClick={e => e.stopPropagation()}>
                  <Field
                    disabled={readOnly}
                    component={CurrencyField}
                    precision={2}
                    name={inputName}
                  />
                </span>
              )}
              {descrizioneIndicatore.inputs[inputName].type === "percent" && (
                <>
                  <span onClick={e => e.stopPropagation()}>
                    <Field
                      disabled={readOnly}
                      component={PercentField}
                      name={inputName}
                    />
                  </span>
                  <span className="indicator-card currency">%</span>
                </>
              )}
              {descrizioneIndicatore.inputs[inputName].type === "switch" && (
                <>
                  <div onClick={e => e.stopPropagation()}>
                    <Field
                      disabled={readOnly}
                      component={CheckboxFieldOptions}
                      trueValue={1}
                      falseValue={0}
                      trueLabel="Si"
                      falseLabel="No"
                      name={inputName}
                    />
                  </div>
                </>
              )}
            </div>
          ))}
        </div>
      </div>
      <Modal isOpen={isModalOpen} toggle={toggleModal} size="lg">
        <ModalHeader className={`${scoreClass} text-black`} toggle={toggleModal}>
          {get(statics, "descrizione_breve", "")}
        </ModalHeader>
        <ModalBody>
          <p style={{ whiteSpace: "pre-wrap" }}>
            {get(statics, "descrizione_estesa", "")}
          </p>
          <p>
            {get(statics, "link", "") !== "" && (
              <>
                {get(statics, "link_requires_auth", false) && (
                  <AuthLink href={get(statics, "link", "")} target="_blank" rel="noreferrer noopener">
                    {"Maggiori informazioni"}
                  </AuthLink>
                )}
                {!get(statics, "link_requires_auth", false) && (
                  <a href={get(statics, "link", "")} target="_blank" rel="noreferrer noopener">
                    {"Maggiori informazioni"}
                  </a>
                )}
              </>
            )}
          </p>
          <p><b>Casi particolari</b></p>
          <p style={{ whiteSpace: "pre-wrap" }}>
            {get(statics, "casi_particolari", "-")}
          </p>
          <p><b>Regole di assegnazione dei punteggi</b></p>
          <ThresholdsTable
            thresholds={thresholds}
            indicatorCode={codiceIndicatore}
          />
        </ModalBody>
      </Modal>
    </>
  )
}

const WatchChanges = ({ bilancio, gruppoIndicatori, onChange }) => {
  // Grab values and submitForm from context
  const { values, setValues } = useFormikContext();
  const prevValues = useRef(null)

  useEffect(() => {

    const realValues = omit(values, "__meta")

    if (!prevValues.current || get(values, "__meta.initializing")) {
      prevValues.current = realValues
      setValues({
        ...values,
        __meta: {
          ...values.__meta,
          initializing: false
        }
      })
      return
    }

    if (isEqual(prevValues.current, realValues)) {
      return
    }
    prevValues.current = realValues

    onChange(realValues)

    setValues({
      ...values,
      __meta: {
        ...values.__meta,
        toSave: true,
      }
    })
  }, [onChange, setValues, values]);

  return null;
};

const IndicatorBlock = ({ bilancio, schema, gruppoIndicatori, renderHeader, pageMatch, thresholds, indicatorsStatics }) => {
  const [{ list: indicatori, error }, { aggiornaIndicatori }] = useRunRj(IndicatoriBilancioState, [deps.maybeGet(bilancio, "id"), gruppoIndicatori])
  const [{ list: indicatoriLive }, { runDebounced: computeLiveIndicators, clean }] = useRj(IndicatoriLiveState)
  const [{ data: notes }, { update: updateNotes }] = useRunRj(AnnotazioneIndicatoriState, [deps.maybeGet(bilancio, "id"), gruppoIndicatori])
  const [computationError, setComputationError] = useState(null)
  const [openNotesModal, setOpenNotesModal] = useState(false)

  const readOnly = bilancio.consolidato

  useEffect(() => {
    const nextError = get(error, "response.detail", null)
    setComputationError(nextError)
  }, [error])

  const prevGruppo = usePrevious(gruppoIndicatori)
  const prevBilancio = usePrevious(bilancio)

  const indicatoriDaMostrare = indicatoriLive || indicatori

  const [
    { list: valoriRettifica },
    { update: aggiornaValoriRettifica }
  ] = useRunRj(
    ValoriRettificaIndicatoriBilancioState,
    [deps.maybeGet(bilancio, "id"), gruppoIndicatori]
  )

  const handleLiveRecomputation = useCallback(inputs => {
    computeLiveIndicators
      .onFailure(resp => {
        setComputationError(get(resp, "error.detail", null))
      })
      .run(
        bilancio.id,
        gruppoIndicatori,
        map(inputs, (value, name) => ({
          bilancio: bilancio.id,
          gruppo: gruppoIndicatori,
          nome: name,
          valore: value !== "" && value !== undefined ? value : 0
        })).filter(item => item.nome !== '__meta')
      )
  }, [bilancio.id, computeLiveIndicators, gruppoIndicatori])

  useEffect(() => {
    if (prevBilancio !== bilancio || prevGruppo !== gruppoIndicatori) {
      clean()
    }
  }, [bilancio, clean, gruppoIndicatori, prevBilancio, prevGruppo])

  if (!schema || !indicatori || !valoriRettifica) {
    return (
      <>
        <Header
          renderHeader={renderHeader}
          schema={schema}
          bilancio={bilancio}
          match={pageMatch}
          readOnly={readOnly}
        />
        <div className="m-2">
          {computationError && (
            <div className="alert alert-warning mx-5 mt-3" role="alert">
              {computationError}
            </div>
          )}
        </div>
      </>
    )
  }

  const initParamsForm = {
    ...mapValues(
      assign({}, ...map(schema[gruppoIndicatori].indicators, indicator => indicator.inputs)),
      (descr, name) => {
        const raw = get(
          valoriRettifica.find(item => item.gruppo === gruppoIndicatori && item.nome === name),
          "valore",
          descr.default
        )
        if (descr.type === "switch") {
          return parseInt(raw, 10)
        }
        return raw
      }
    ),
    __meta: { toSave: false }
  }

  return (
    <Formik
      initialValues={initParamsForm}
      onSubmit={(values, actions) => {
        aggiornaValoriRettifica
          .onSuccess(() => {
            aggiornaIndicatori
              .onSuccess(() => {
                actions.setValues({
                  ...values,
                  __meta: {
                    ...values.__meta,
                    toSave: false
                  }
                })
                actions.setSubmitting(false)
              })
              .run(bilancio.id, gruppoIndicatori)
          })
          .run(
            bilancio.id,
            gruppoIndicatori,
            map(values, (value, name) => ({
              bilancio: bilancio.id,
              gruppo: gruppoIndicatori,
              nome: name,
              valore: value !== "" && value !== undefined ? value : 0
            })).filter(item => item.nome !== '__meta')
          )

      }}
    >
      {({ isSubmitting, handleSubmit, values }) => (
        <>
          <Header
            renderHeader={renderHeader}
            schema={schema}
            bilancio={bilancio}
            match={pageMatch}
            isSaving={isSubmitting}
            shouldSave={values.__meta.toSave}
            aggiornaIndicatori={() => {
              handleSubmit()
            }}
            readOnly={readOnly}
          />
          <div className="m-2">
            {computationError && (
              <div className="alert alert-warning mx-5 mt-3" role="alert">
                {computationError}
              </div>
            )}
            <form onSubmit={handleSubmit}>
              {find(schema[gruppoIndicatori].indicators, (descr, code) => code === "IdA0" || code === "IdAp0") && (
                <div className="indicator-card wrapper oneline mb-5">
                  {map(pick(schema[gruppoIndicatori].indicators, ["IdA0", "IdAp0"]), (indicator, indicatorCode) => (
                    <IndicatorCard
                      indicatorsStatics={indicatorsStatics}
                      key={indicatorCode}
                      gruppo={gruppoIndicatori}
                      codiceIndicatore={indicatorCode}
                      descrizioneIndicatore={indicator}
                      valoriIndicatori={indicatoriDaMostrare}
                      indicatore={indicatoriDaMostrare.find(item => item.codice === indicatorCode)}
                      readOnly={readOnly}
                      thresholds={thresholds}
                    />
                  ))}
                </div>
              )}
              <div className="indicator-card wrapper">
                {map(omit(schema[gruppoIndicatori].indicators, ["IdA0", "IdAp0"]), (indicator, indicatorCode) => (
                  <IndicatorCard
                    indicatorsStatics={indicatorsStatics}
                    key={indicatorCode}
                    gruppo={gruppoIndicatori}
                    codiceIndicatore={indicatorCode}
                    descrizioneIndicatore={indicator}
                    valoriIndicatori={indicatoriDaMostrare}
                    indicatore={indicatoriDaMostrare.find(item => item.codice === indicatorCode)}
                    readOnly={readOnly}
                    thresholds={thresholds}
                  />
                ))}
              </div>
            </form>
            {notes !== null && (
              <>
                <div className="row">
                  <div className="col-12">
                    <div className="p-2">
                      <div className="d-flex flex-row justify-content-between align-items-center pb-1 border-bottom">
                        <b>Annotazioni</b>
                        <button
                          type="button"
                          className="btn btn-primary btn-sm"
                          onClick={() => setOpenNotesModal(true)}
                        >Modifica annotazioni</button>
                      </div>
                      <small>
                        <i style={{ whiteSpace: "pre-line" }}>
                          {notes.note !== "" ? notes.note : "(Nessuna nota)"}
                        </i>
                      </small>
                    </div>
                  </div>
                </div>
                {openNotesModal && (
                  <NotesModal
                    title={`Annotazioni ${schema[gruppoIndicatori].name}`}
                    initialValue={notes.note || ""}
                    onSave={nextNote => {
                      return updateNotes.asPromise(bilancio.id, gruppoIndicatori, nextNote)
                    }}
                    toggle={() => setOpenNotesModal(false)}
                  />
                )}
              </>
            )}
          </div>
          {!readOnly && (
            <WatchChanges
              bilancio={bilancio}
              gruppoIndicatori={gruppoIndicatori}
              onChange={handleLiveRecomputation}
            />
          )}
        </>
      )}
    </Formik>
  )
}

const IndicatoriBilancio = ({ bilancio, renderHeader, match: pageMatch, groups }) => {
  const [{ data: schema }] = useRunRj(SchemaIndicatoriState)
  const { currentAzienda } = useContext(AziendaContext)
  const [{ data: thresholds }] = useRunRj(ThresholdsState, [deps.maybeGet(currentAzienda, "settore")])
  const [{ data: indicatorsStatics }] = useRunRj(IndicatorStaticsState, [])

  if (!schema || !thresholds || !indicatorsStatics) {
    return null
  }

  return (
    <Switch>
      <Route path={`${pageMatch.path}/:group`} render={({ match }) => {
        const groupCode = match.params.group

        return (
          <IndicatorBlock
            bilancio={bilancio}
            schema={pick(schema, groups)}
            gruppoIndicatori={groupCode}
            renderHeader={renderHeader}
            pageMatch={pageMatch}
            thresholds={thresholds}
            indicatorsStatics={keyBy(indicatorsStatics, "codice")}
          />
        )

      }}
      />
      <Route render={() => {
        if (schema) {
          const redirectToGroup = groups.find(group => !bilancio.bilancio_chiusura || schema[group]["fine_esercizio"])
          return <Redirect push={false} to={`${pageMatch.url}/${redirectToGroup}`} />
        } else {
          return <Header renderHeader={renderHeader} schema={schema} match={pageMatch} />
        }
      }}
      />
    </Switch>
  )
}

export default IndicatoriBilancio