import React from "react"
import { useRunRj, deps } from "react-rocketjump"
import { AnnotazioneIndicatoriState } from "../../localstate/bilanci"
import ReportHeader from "./Header"
import IndicatorCard from "./IndicatorCard"
import sortBy from "lodash/sortBy"
import get from "lodash/get"

const IdAPage = props => {
  const { bilancio, schemaIndicatori, thresholds, indicatorsStatics, indicatoriIda: indicatori } = props
  const [{ data: notes }] = useRunRj(AnnotazioneIndicatoriState, [deps.maybeGet(bilancio, "id"), "IdA"])

  if (!indicatori) {
    return null
  }

  const ida0 = indicatori.find(i => i.codice === "IdA0")
  const idax = indicatori.filter(i => i.codice !== "IdA0")

  const IdAIndicators = schemaIndicatori["IdA"].indicators

  return (
    <>
      <div className="page indicator-page ida-page">
        <ReportHeader {...props} />
        <h1>INDICI DI ALLERTA CONSUNTIVI</h1>
        <p className="under_title">art.13 comma 2 codice della Crisi e dell'Insolvenza</p>
        <h2>Patrimonio netto negativo</h2>
        <p>
          {"Tale indice è rilevabile direttamente dal dato del “patrimonio netto” (totale voce A, sezione “passivo” dello\
          stato patrimoniale, art. 2424 cod. civ.), cui sottrarre i “crediti verso soci per versamenti ancora dovuti”\
          (voce A, stato patrimoniale attivo), eventuali dividendi deliberati non ancora contabilizzati.\
          Nel “patrimonio netto” non si tiene conto dell’eventuale “Riserva per operazioni di copertura dei flussi finanziari\
          attesi”, indipendentemente dal suo saldo, in linea con quanto disposto dall’art. 2426 c.c., co. 1, n. 11-bis."}
        </p>
        <div className="grid-1x3">
          <IndicatorCard
            indicatorsStatics={indicatorsStatics}
            thresholds={thresholds}
            indicatorName="IdA0"
            indicatorDescription={IdAIndicators["IdA0"]}
            indicatorValue={ida0}
          />
        </div>
        <h2>Indici settoriali</h2>
        <p>
          {"Se il patrimonio netto è positivo e il capitale sociale è sopra il limite legale e se il DSCR non è disponibile oppure\
          è ritenuto non sufficientemente affidabile per la inadeguata qualità dei dati prognostici, si adottano i seguenti 5\
          indici, con soglie diverse a seconda del settore di attività, che devono allertarsi tutti congiuntamente"}
        </p>
        <div className="grid-2x3">
          {sortBy(idax, "codice").filter(i => !!IdAIndicators[i.codice]).map(indicatorValue => (
            <IndicatorCard
              indicatorsStatics={indicatorsStatics}
              key={indicatorValue.codice}
              thresholds={thresholds}
              indicatorName={indicatorValue.codice}
              indicatorDescription={IdAIndicators[indicatorValue.codice]}
              indicatorValue={indicatorValue}
            />
          ))}
        </div>
      </div>
      {get(notes, "note", undefined) && (
        <div className="page indicator-page">
          <ReportHeader {...props} />
          <h1>INDICI DI ALLERTA CONSUNTIVI</h1>
          <p className="under_title">art.13 comma 2 codice della Crisi e dell'Insolvenza</p>
          <h2>Annotazioni</h2>
          <p className="note">{get(notes, "note", null)}</p>
        </div>
      )}
    </>
  )

}

export default IdAPage