import React, { Component } from 'react';
import * as Sentry from '@sentry/browser';
import Header from './Header';

class SentryBoundary extends Component {
  constructor(props) {
    super(props);
    this.state = { eventId: null, hasError: false };
  }

  componentDidCatch(error, errorInfo) {
    if (process.env.NODE_ENV === "production") {
      Sentry.withScope((scope) => {
        scope.setExtras(errorInfo);
        const eventId = Sentry.captureException(error);
        this.setState({ eventId, hasError: true });
      });
    } else {
      this.setState({ eventId: 0, hasError: true })
    }
  }

  render() {
    if (this.state.hasError) {
      //render fallback UI
      return (
        <div>
          <Header />
          <div className="mt-4">
            <div className="row no-gutters">
              <div className="col-md-4 offset-md-4">
                <div className="text-center">
                  <h4>Errore</h4>
                  <p>Si è verificato un errore imprevisto</p>
                  <p>
                    <button
                      className="btn btn-primary btn-sm"
                      onClick={() => Sentry.showReportDialog({ eventId: this.state.eventId })}
                    >
                      Invia una segnalazione
                </button>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      );
    }

    //when there's not an error, render children untouched
    return this.props.children;
  }
}

export default SentryBoundary