import React, { useCallback, useState } from "react"
import { useGenerateReport } from "../../localstate/report"
import { Spinner } from "reactstrap"
import { Link } from "react-router-dom"
import BilancioLandmarks from "./BilancioLandmarks"
import moment from "moment"
import ReportBilancio from "../Report/Report"
import { FaAngleRight } from "react-icons/fa"
import DemoDownloadPDFModal from "./DemoDownloadPDFModal"
import { useAuthUser } from "use-eazy-auth"

const StampaReport = ({ bilancio }) => {
  const bilancioId = bilancio && bilancio.id
  const { user } = useAuthUser()
  const isDemo = user.dati_licenza?.is_demo ?? false

  const [generateReport, { pending }] = useGenerateReport()
  const [demoDownloadReport, setDemoDownloadReport] = useState(false)

  const download = useCallback(() => {
    if (!isDemo) {
      generateReport(bilancioId)
    } else {
      setDemoDownloadReport(true)
    }
  }, [bilancioId, generateReport, isDemo, setDemoDownloadReport])

  if (!bilancioId) {
    return null
  }

  return (
    <>
      <div className="sticky-header bg-light p-3">
        <div className="d-flex justify-content-between align-items-center w-100">
          <h4 className="m-0 d-inline-flex align-items-center">
            <Link to={`/bilanci`}>Bilanci</Link>
            <FaAngleRight className="mx-2" />
            {moment(bilancio.data_bilancio).format("DD/MM/YYYY")}
          </h4>
          <BilancioLandmarks bilancio={bilancio} />
        </div>
      </div>
      <ReportBilancio bilancioId={bilancio.id} />
      <div className="sticky-footer bg-light border-top p-3">
        <div className="d-flex justify-content-between align-items-center w-100">
          <div className="d-flex flex-row justify-content-start align-items-center w-25">
            {/* LEFT */}
          </div>
          <div className="d-flex flex-row align-items-center justify-content-center w-50">
            {/* CENTER */}
          </div>
          <div className="d-flex flex-row justify-content-end align-items-center w-25">
            <button
              type="button"
              onClick={download}
              disabled={pending}
              className="btn btn-sm btn-primary mr-1"
            >
              {"Scarica PDF"}
              {pending && <Spinner size="sm" className="ml-1" />}
            </button>
          </div>
        </div>
      </div>
      {demoDownloadReport && (
        <DemoDownloadPDFModal onCancel={() => setDemoDownloadReport(false)} />
      )}
    </>
  )
}

export default StampaReport
