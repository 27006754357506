import React from "react"
import { Formik, Field } from "formik"
import {
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Button,
  Spinner
} from "reactstrap"

const NotesModal = ({ title, initialValue, onSave, toggle }) => {
  return (
    <Formik
      initialValues={{ notes: initialValue }}
      onSubmit={(values, actions) => {
        onSave(values.notes)
          .then(() => {
            actions.setSubmitting(false)
            setTimeout(toggle, 100)
          })
          .catch(() => {
            actions.setSubmitting(false)
          })
      }}
    >
      {({ handleSubmit, isSubmitting }) => {
        return (
          <Modal isOpen={true} toggle={toggle}>
            <ModalHeader toggle={toggle} className="bg-primary text-white">
              {title}
            </ModalHeader>
            <ModalBody>
              <Field
                name="notes"
                component="textarea"
                rows={10}
                className="w-100 form-control form-control-sm"
              />
            </ModalBody>
            <ModalFooter>
              <div className="w-100 h-100 d-flex flex-row justify-content-between">
                <Button
                  disabled={isSubmitting}
                  color="secondary"
                  type="button"
                  onClick={() => {
                    toggle()
                  }}
                >
                  Annulla
                </Button>
                <Button
                  disabled={isSubmitting}
                  color="success"
                  type="button"
                  onClick={handleSubmit}
                >
                  <span className="mx-1">Salva</span>
                  {isSubmitting && <Spinner size="sm" />}
                </Button>
              </div>
            </ModalFooter>
          </Modal>
        )
      }}
    </Formik>
  )
}

export default NotesModal
