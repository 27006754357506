import React, { useState } from "react"
import { useAuthUser } from "use-eazy-auth"
import Header from "../../components/Header"
import Menu from "../../components/Menu"
import Layout from "react-drawers"
import { Modal } from "reactstrap"
import ProfileForm from "./ProfileForm"
import { useRunRj, deps } from "react-rocketjump"
import { CommercialistaState } from "../../localstate/commercialista"

const Profile = () => {
  const { user } = useAuthUser();

  const [{ data: commercialista }, { update }] = useRunRj(CommercialistaState, [deps.maybeGet(user, "dati_commercialista.id")])

  const [editing, setEditing] = useState(false);
  const toggleEditing = () => setEditing(!editing);

  if (!commercialista) {
    return null
  }

  return (
    <Layout absolute className="classname-applied-to-content">
      <Layout.Top className="top-classname">
        <Header></Header>
      </Layout.Top>
      <Layout.Left className="left-classname">
        <Menu></Menu>
      </Layout.Left>
      <Layout.Content>
        <div className="sticky-header bg-light p-3">
          <div className="d-flex justify-content-between align-items-center w-100">
            <h4 className="m-0">
              Profilo utente
            </h4>
          </div>
        </div>

        <div className="p-3">
          <div className="card">
            <div className="card-header">Dati studio</div>
            <div className="card-body">
              {commercialista && (
                <div className="row">
                  <div className="col-8">
                    <dl className="row">
                      <dt className="col-sm-2">Nome studio</dt>
                      {commercialista.nome_studio && (
                        <dd className="col-sm-10">{commercialista.nome_studio}</dd>
                      )}
                      {!commercialista.nome_studio && (
                        <dd className="col-sm-10">
                          <i>Non specificato</i>
                        </dd>
                      )}
                    </dl>
                    <dl className="row">
                      <dt className="col-sm-2">Licenza</dt>
                      {user.dati_licenza && user.dati_licenza.is_demo && (
                        <dd className="col-sm-10">Demo</dd>
                      )}
                      {user.dati_licenza && !user.dati_licenza.is_demo && (
                        <dd className="col-sm-10">Valida</dd>
                      )}
                      {!user.dati_licenza && (
                        <dd className="col-sm-10">Staff</dd>
                      )}
                    </dl>
                  </div>
                  <div className="col-4 d-flex justify-content-center align-items-center">
                    {commercialista.logo_studio && (
                      <img src={commercialista.logo_studio} style={{ width: 150, height: 150, objectFit: "contain" }} alt="Logo commercialista" />
                    )}
                  </div>
                </div>
              )}
            </div>
            <div className="card-footer text-center">
              <button
                className="btn btn-sm btn-outline-dark"
                onClick={toggleEditing}
              >
                Modifica dati
              </button>
            </div>
          </div>
        </div>

        <Modal isOpen={editing} size="lg">
          <ProfileForm
            initialValues={commercialista}
            save={values => update.asPromise(commercialista.id, values)}
            toggleModal={toggleEditing}
          />
        </Modal>

        <div className="sticky-footer bg-light border-top"></div>
      </Layout.Content>
    </Layout>
  );
};

export default Profile;
