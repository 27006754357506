import React from "react"
import qs from "query-string"
import { BrowserRouter as Router, Switch, Redirect } from "react-router-dom"
import { ConfigureRj } from "react-rocketjump"
import EazyAuth from "use-eazy-auth"
import { meCall, loginCall } from "./auth"
import { AuthRoute, GuestRoute } from "use-eazy-auth/routes"
import Login from "./pages/Login"
import Profile from "./pages/Profile"
import Layout from "react-drawers"
import { AziendaProvider, ConfigProvider } from "./providers"
import Aziende from "./pages/Aziende/Aziende"
import Azienda from "./pages/Aziende/Azienda"
import Bilanci from "./pages/Bilanci/Bilanci"
import Bilancio from "./pages/Bilanci/Bilancio"
import ReportBilancio from "./pages/Report/ReportPage"
import GiuffreAuth from "./pages/GiuffreAuth"
import useRouter from "magik-react-hooks/useRouter"
import SentryBoundary from "./components/SentryBoundary"
import ProxyStorage from "./storage"
import { GiuffreAuthWatch } from "./components/GiuffreAuthWatch"

// Fake up Eazy Auth
const requestQueryString = qs.parse(window.location.search)
const PDFAuthToken = requestQueryString["__pdf__authtoken"]
const PDFStorageBackend = {
  getItem: () =>
    JSON.stringify({
      expires: null,
      accessToken: PDFAuthToken
    }),
  setItem: () => {},
  removeItem: () => {}
}
const storageBackend = PDFAuthToken ? PDFStorageBackend : ProxyStorage

const Auth = ({ children }) => (
  <EazyAuth
    storageBackend={storageBackend}
    loginCall={loginCall}
    meCall={meCall}
    render={({ callAuthApiObservable }) => (
      <ConfigureRj effectCaller={callAuthApiObservable}>{children}</ConfigureRj>
    )}
  />
)

const MyAuthRoute = props => {
  const { location } = useRouter()
  return (
    <AuthRoute
      redirectTo={{
        ...location,
        pathname: "/login"
      }}
      {...props}
    />
  )
}

function App() {
  return (
    <SentryBoundary>
      <Auth>
        <GiuffreAuthWatch />
        <ConfigProvider>
          <AziendaProvider>
            <Router>
              <Layout.Master
                top={70}
                left={240}
                right={300}
                bottom={54}
                mode="TLBR"
                rightOpenOnStart={false}
                leftCollapsedWidth={60}
              >
                <Switch>
                  <GuestRoute path="/login" component={GiuffreAuth} />
                  <GuestRoute path="/staff" component={Login} />
                  <MyAuthRoute path="/profile" exact component={Profile} />
                  <MyAuthRoute path="/aziende" exact component={Aziende} />
                  <MyAuthRoute path="/aziende/:id" exact component={Azienda} />
                  <MyAuthRoute path="/bilanci" exact component={Bilanci} />
                  <MyAuthRoute
                    path="/bilanci/:id/report"
                    component={ReportBilancio}
                  />
                  <MyAuthRoute path="/bilanci/:id" component={Bilancio} />
                  <Redirect to="/aziende" />
                </Switch>
              </Layout.Master>
            </Router>
          </AziendaProvider>
        </ConfigProvider>
      </Auth>
    </SentryBoundary>
  )
}

export default App
