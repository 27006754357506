import React, { useMemo, useEffect, useRef, useState, useCallback } from "react"
import { useRunRj, useRj } from "react-rocketjump"
import {
  BilancioSchemaState,
  CheckBilancioDetailState,
  ParseXbrlState,
  BilancioAutoProjectState
} from "../../localstate/bilanci"
import keyBy from "lodash/keyBy"
import get from "lodash/get"
import omit from "lodash/omit"
import { Formik, FastField as Field, useFormikContext } from "formik"
import isEqual from "lodash/isEqual"
import { FiPlusSquare, FiMinusSquare } from "react-icons/fi"
import XbrlImportModal from "./XbrlImportModal"
import "./style.scss"
import { putSpacesEvery000 } from "../../utils"
import BilancioEditorFooter from "./BilancioEditorFooter"
import { Spinner, Collapse } from "reactstrap"
import { FaSave } from "react-icons/fa"
import NotesModal from "./NotesModal"
import { format } from "date-fns"
import CopyFromBilancioModal from "./CopyFromBilancioModal"
import CurrencyField from "../../components/Fields/CurrencyField"
import XbrlWarnModal from "./XbrlWarnModal"

const initializeValues = obj =>
  JSON.parse(JSON.stringify(obj, (k, v) => (v === null ? "" : v)))

const nullifyValues = obj =>
  JSON.parse(JSON.stringify(obj, (k, v) => (v === "" ? null : v)))

const getKeyForCodice = codice => {
  const pieces = codice.split(".")
  return pieces.map(piece => `[${piece}]`).join(".children")
}

const getChildrenKeyForCodice = codice => {
  return getKeyForCodice(codice) + ".children"
}

const prettyPrintFormula = formula => {
  if (formula.length === 0) {
    return null
  }
  const out = formula
    .map(addendum => {
      if (addendum.startsWith("-")) {
        return " - " + addendum.substr(1)
      }
      return " + " + addendum
    })
    .join("")
  if (out.startsWith(" + ")) {
    return "= " + out.substr(3)
  } else {
    return "= " + out
  }
}

const coalesce = (...args) => {
  return args.find(arg => arg !== undefined && arg !== null && arg !== "") || ""
}

const VoceBilancioMemo = React.memo(VoceBilancio)

function VoceBilancio({
  schema,
  codice,
  toggleOpen,
  openVoci,
  depth = 0,
  vociBilancioBase,
  vociBilancioFineEsercizio,
  readOnly = false
}) {
  const vociChildren = get(schema, getChildrenKeyForCodice(codice))
  const vociIndex = get(schema, `${getKeyForCodice(codice)}.index`, null)
  const rootKeys = useMemo(() => {
    if (!vociChildren) {
      return []
    }
    if (vociIndex) {
      return vociIndex
    }
    return Object.keys(vociChildren)
  }, [vociChildren, vociIndex])

  const codiceSafe = codice.replace(/\./g, "-")
  const cfg = get(schema, getKeyForCodice(codice))
  const disabled = cfg.valore !== "input" || readOnly
  const isOpen = get(openVoci, codice)

  const { values } = useFormikContext()

  const valoreCorrente = get(values, `${codiceSafe}.valore`)
  const valoreRettificato = get(values, `${codiceSafe}.rettifica`)
  const rettificaPercentuale = valoreRettificato
    ? parseFloat(valoreCorrente) !== 0
      ? (((valoreRettificato - valoreCorrente) / valoreCorrente) * 100).toFixed(
          2
        ) + " %"
      : "N/D"
    : ""

  const valuesToInfix = [vociBilancioBase, vociBilancioFineEsercizio].filter(
    item => item
  ).length

  return (
    <div className="">
      <div>
        <div className="row no-gutters border-bottom show-hover">
          <div
            className={`col-${7 -
              valuesToInfix * 2} d-flex flex-column p-2 justify-content-center`}
          >
            <div
              style={{ paddingLeft: depth * 15 }}
              className="flex-1 d-flex flex-row align-items-center"
            >
              <div>
                {rootKeys.length > 0 && (
                  <>
                    {isOpen && (
                      <FiMinusSquare
                        className="pointer mr-2"
                        onClick={() => toggleOpen(codice)}
                      />
                    )}
                    {!isOpen && (
                      <FiPlusSquare
                        className="pointer mr-2"
                        onClick={() => toggleOpen(codice)}
                      />
                    )}
                  </>
                )}
                {rootKeys.length === 0 && (
                  <FiMinusSquare className="mr-2 invisible" />
                )}
              </div>
              <div>
                <p className="p-0 m-0">{cfg.titolo}</p>
                <span className="badge badge-secondary">{codice}</span>
                {cfg.formula && (
                  <span className="badge badge-warning">
                    {cfg.formula && prettyPrintFormula(cfg.formula)}
                  </span>
                )}
              </div>
            </div>
          </div>
          {vociBilancioFineEsercizio && (
            <div className="col-2 border-right d-flex flex-column justify-content-center">
              <div className="p-2">
                <div className="m-0">
                  <input
                    disabled
                    className="text-nowrap form-control-sm d-block border-0 bg-white w-100 text-right"
                    value={putSpacesEvery000(
                      coalesce(
                        get(
                          vociBilancioFineEsercizio,
                          `${codiceSafe}.rettifica`,
                          null
                        ),
                        get(
                          vociBilancioFineEsercizio,
                          `${codiceSafe}.valore`,
                          null
                        ),
                        "0.00"
                      ) + " €"
                    )}
                  />
                </div>
              </div>
            </div>
          )}
          {vociBilancioBase && (
            <div className="col-2 border-right d-flex flex-column justify-content-center">
              <div className="p-2">
                <div className="m-0">
                  <input
                    disabled
                    className="text-nowrap form-control-sm d-block border-0 bg-white w-100 text-right"
                    value={putSpacesEvery000(
                      coalesce(
                        get(vociBilancioBase, `${codiceSafe}.rettifica`, null),
                        get(vociBilancioBase, `${codiceSafe}.valore`, null),
                        "0.00"
                      ) + " €"
                    )}
                  />
                </div>
              </div>
            </div>
          )}
          <div className="col-sm-2">
            <div className="p-2">
              <Field
                name={`${codiceSafe}.valore`}
                component={CurrencyField}
                label={"Valore"}
                labelClassName="mb-1 p-0 small"
                formGroupClassName="m-0"
                disabled={disabled}
                // type="number"
                className={`w-100 text-right ${
                  get(values, `${codiceSafe}.has_error`, false)
                    ? "bg-warning"
                    : ""
                }`}
                bsSize="sm"
              ></Field>
            </div>
          </div>
          <div className="col-sm-2">
            <div className="p-2">
              {cfg.valore === "input" && (
                <Field
                  name={`${codiceSafe}.rettifica`}
                  component={CurrencyField}
                  label={"Valore Alternativo"}
                  labelClassName="mb-1 p-0 small"
                  formGroupClassName="m-0"
                  disabled={disabled}
                  // type="number"
                  className="w-100 text-right bg-lightcyan"
                  bsSize="sm"
                ></Field>
              )}
            </div>
          </div>
          <div className="col-1 flex-0">
            <div className="p-2">
              {cfg.valore === "input" && (
                <div className="m-0 form-group">
                  <label className="text-left my-1 p-0 small d-block">
                    Variazione %
                  </label>
                  {cfg.valore === "input" && (
                    <input
                      disabled
                      className="text-nowrap form-control-sm d-block border-0 bg-white"
                      value={rettificaPercentuale}
                    />
                  )}
                </div>
              )}
            </div>
          </div>
        </div>
      </div>

      {isOpen &&
        rootKeys.map(rootKey => (
          <VoceBilancioMemo
            openVoci={openVoci}
            toggleOpen={toggleOpen}
            key={rootKey}
            schema={schema}
            codice={`${codice}.${rootKey}`}
            depth={depth + 1}
            vociBilancioBase={vociBilancioBase}
            vociBilancioFineEsercizio={vociBilancioFineEsercizio}
            readOnly={readOnly}
          ></VoceBilancioMemo>
        ))}
    </div>
  )
}

const CheckBilancio = ({ checkBilancio, id, schema }) => {
  // Grab values and submitForm from context
  const { values, setValues } = useFormikContext()
  const prevInput = useRef(null)
  const skipId = useRef(0)

  useEffect(() => {
    const vociInput = Object.values(values).filter(voce => {
      if (!voce.codice) {
        return false
      }
      const schemaKey = getKeyForCodice(voce.codice)
      const cfg = get(schema, schemaKey)
      return cfg.valore === "input"
    })

    if (!prevInput.current || get(values, "__meta.initializing")) {
      prevInput.current = vociInput
      setValues({
        ...values,
        __meta: {
          ...values.__meta,
          initializing: false
        }
      })
      return
    }

    if (isEqual(prevInput.current, vociInput)) {
      return
    }

    prevInput.current = vociInput
    skipId.current = skipId.current + 1
    const currId = skipId.current

    checkBilancio
      .onSuccess(result => {
        if (skipId.current !== currId) {
          return
        }
        const voci = result.voci.filter(voce => {
          const schemaKey = getKeyForCodice(voce.codice)
          const cfg = get(schema, schemaKey)
          return cfg.valore !== "input"
        })

        setValues({
          ...values,
          ...keyBy(
            voci.map(v => ({ ...v, has_error: false })),
            x => x.codice.replace(/\./g, "-")
          ),
          __meta: {
            ...values.__meta,
            toSave: true,
            quadratura: result.quadratura
          }
        })
      })
      .run(id, {
        voci_test: nullifyValues(vociInput.map(item => omit(item, "has_error")))
      })
  }, [values, checkBilancio, id, setValues, schema])
  return null
}

function setOpenRecursive(open, pieces) {
  const [piece, ...others] = pieces
  if (others.length === 0) {
    return {
      ...open,
      [piece]: open[piece] ? undefined : {}
    }
  }
  return {
    ...open,
    [piece]: setOpenRecursive(open[piece] || {}, others)
  }
}

function setAllOpenRecursive(open) {
  return Object.keys(open).reduce(
    (all, key) => ({
      [key]: open[key].children ? setAllOpenRecursive(open[key].children) : {},
      ...all
    }),
    {}
  )
}

const getVociByCodice = bilancio => {
  const voci = get(bilancio, "voci", [])
  const byCodice = keyBy(voci, x => x.codice.replace(/\./g, "-"))
  return initializeValues(byCodice)
}

export default function BilancioEditor({
  bilancio,
  bilancioBase = null,
  bilancioFineEsercizio = null,
  save,
  renderHeader,
  footerComponent = BilancioEditorFooter
}) {
  const [{ schema }] = useRunRj(BilancioSchemaState, [])
  const { runDebounced: checkBilancio } = useRj(CheckBilancioDetailState)[1]
  const { run: importXbrl } = useRj(ParseXbrlState)[1]
  const [
    { pending: isProjectionPending },
    { run: performAutoProjection }
  ] = useRj(BilancioAutoProjectState)
  const [openXbrlModal, setOpenXbrlModal] = useState(false)
  const [openXbrlWarnModal, setOpenXbrlWarnModal] = useState(false)
  const [openCopyModal, setOpenCopyModal] = useState(false)
  const [openNotesModal, setOpenNotesModal] = useState(false)
  const [displayNotes, setDisplayNotes] = useState(false)

  const toggleXbrlModal = useCallback(() => {
    setOpenXbrlModal(prev => !prev)
  }, [])

  const toggleXbrlWarnModal = useCallback(() => {
    setOpenXbrlWarnModal(prev => !prev)
  }, [])

  const vociByCodice = useMemo(() => {
    return {
      ...getVociByCodice(bilancio),
      __meta: {
        toSave: false,
        quadratura: bilancio.quadratura,
        initializing: true
      }
    }
  }, [bilancio])

  const vociByCodiceBilancioBase = useMemo(() => {
    return bilancioBase && getVociByCodice(bilancioBase)
  }, [bilancioBase])

  const vociByCodiceBilancioFineEsercizio = useMemo(() => {
    return bilancioFineEsercizio && getVociByCodice(bilancioFineEsercizio)
  }, [bilancioFineEsercizio])

  const rootKeys = useMemo(() => {
    if (!schema) {
      return []
    }
    return Object.keys(schema)
  }, [schema])

  // TODO: maybe custom hook with schema
  const [openVoci, setOpenVoci] = useState({})
  function toggleOpen(codice) {
    const pieces = codice.split(".")
    setOpenVoci(setOpenRecursive(openVoci, pieces))
  }

  function expandAll() {
    setOpenVoci(setAllOpenRecursive(schema))
  }
  function collapseAll() {
    setOpenVoci({})
  }

  // TODO: grab schema earlier
  useEffect(() => {
    if (schema) {
      const initalOpen = Object.keys(schema).reduce(
        (opens, key) => ({
          ...opens,
          [key]: {}
        }),
        {}
      )
      setOpenVoci(initalOpen)
    }
  }, [schema])

  const FooterComponent = footerComponent

  return (
    bilancio &&
    schema && (
      <Formik
        onSubmit={(values, actions) => {
          save(bilancio, values).then(bilancio => {
            actions.setSubmitting(false)
            actions.setValues({
              ...values,
              __meta: {
                ...values.meta,
                quadratura: bilancio.quadratura,
                toSave: false
              }
            })
          })
        }}
        initialValues={vociByCodice}
      >
        {({ values, handleSubmit, isSubmitting, setValues }) => (
          <>
            <form onSubmit={handleSubmit}>
              {renderHeader &&
                renderHeader({
                  header: (
                    <FooterComponent
                      readOnly={
                        bilancio.consolidato ||
                        (bilancioBase && bilancioBase.consolidato)
                      }
                      isSubmitting={isSubmitting}
                      expandAll={expandAll}
                      collapseAll={collapseAll}
                      showXbrlModal={() => setOpenXbrlModal(true)}
                      showCopyModal={() => setOpenCopyModal(true)}
                      performAutoProjection={
                        bilancioBase === null
                          ? undefined
                          : () => {
                              performAutoProjection
                                .onSuccess(data => {
                                  const voci = data
                                  setValues({
                                    ...values,
                                    ...keyBy(voci, x =>
                                      x.codice.replace(/\./g, "-")
                                    )
                                  })
                                })
                                .run(bilancioBase.id)
                            }
                      }
                      isPerformingProjection={isProjectionPending}
                      shouldSave={get(values, "__meta.toSave", false)}
                      quadratura={get(values, "__meta.quadratura", 0)}
                    />
                  )
                })}
              <div className="pb-3">
                <div>
                  <div
                    className="row no-gutters bg-light border-top"
                    style={{ position: "sticky", top: 70, zIndex: 100 }}
                  >
                    <div
                      className={`col-${7 -
                        [
                          vociByCodiceBilancioBase,
                          vociByCodiceBilancioFineEsercizio
                        ].filter(item => item).length *
                          2}`}
                    >
                      <p className="p-1 pl-3 m-0">
                        <small>Voce bilancio</small>
                      </p>
                    </div>
                    {vociByCodiceBilancioFineEsercizio && (
                      <div className="col-2 border-right">
                        <p className="p-1 m-0 text-right">
                          <small>Fine esercizio</small>
                        </p>
                      </div>
                    )}
                    {vociByCodiceBilancioBase && (
                      <div className="col-2 border-right">
                        <p className="p-1 m-0 text-right">
                          <small>Valore attuale</small>
                        </p>
                      </div>
                    )}
                    <div className="col-5 text-right p-1 m-0 pr-3">
                      <button
                        id="toggle-notes"
                        className="pointer btn btn-sm btn-secondary"
                        style={{ padding: "2px 4px", lineHeight: "1.2" }}
                        onClick={() => {
                          setDisplayNotes(v => !v)
                        }}
                      >
                        <small>
                          {displayNotes ? "Nascondi note" : "Mostra note"}
                        </small>
                      </button>
                    </div>
                  </div>
                  <Collapse
                    isOpen={displayNotes}
                    toggler="toggle-notes"
                    style={{ position: "sticky", top: 101, zIndex: 100 }}
                  >
                    <div className="row no-gutters bg-light border-top">
                      <div className="col-12 p-2">
                        <div style={{ maxHeight: "25vh", overflow: "auto" }}>
                          <small>
                            <i style={{ whiteSpace: "pre-line" }}>
                              {bilancio.note}
                              {(bilancio.note === null ||
                                bilancio.note === "") && (
                                <span>(Nessuna nota)</span>
                              )}
                            </i>
                          </small>
                        </div>
                        <div className="text-right pt-1">
                          <button
                            className="btn btn-primary btn-sm"
                            onClick={() => setOpenNotesModal(true)}
                          >
                            {"Modifica"}
                          </button>
                        </div>
                      </div>
                    </div>
                  </Collapse>
                  {rootKeys.map(rootKey => (
                    <VoceBilancioMemo
                      readOnly={
                        isSubmitting ||
                        bilancio.consolidato ||
                        (bilancioBase && bilancioBase.consolidato)
                      }
                      openVoci={openVoci}
                      toggleOpen={toggleOpen}
                      key={rootKey}
                      schema={schema}
                      codice={rootKey}
                      vociBilancioBase={vociByCodiceBilancioBase}
                      vociBilancioFineEsercizio={
                        vociByCodiceBilancioFineEsercizio
                      }
                    ></VoceBilancioMemo>
                  ))}
                </div>
                <CheckBilancio
                  id={bilancio.id}
                  checkBilancio={checkBilancio}
                  schema={schema}
                ></CheckBilancio>
              </div>
              <XbrlImportModal
                isOpen={openXbrlModal}
                toggle={toggleXbrlModal}
                xbrlImport={file => {
                  return importXbrl
                    .onSuccess(data => {
                      const voci = data.voci.map(v => ({
                        has_error: false,
                        ...v
                      }))
                      const vociByCodice = keyBy(voci, x =>
                        x.codice.replace(/\./g, "-")
                      )
                      const vociInitialized = initializeValues(vociByCodice)
                      setValues({ ...values, ...vociInitialized })
                      toggleXbrlModal()
                      if (data.warning !== "none") {
                        setOpenXbrlWarnModal(true)
                      }
                    })
                    .asPromise(bilancio.id, file)
                }}
              />
              {openCopyModal && (
                <CopyFromBilancioModal
                  bilancio={bilancio}
                  toggle={() => setOpenCopyModal(false)}
                  onComplete={voci => {
                    setValues({
                      ...values,
                      ...keyBy(
                        voci.map(v => ({ has_error: false, ...v })),
                        x => x.codice.replace(/\./g, "-")
                      )
                    })
                    setOpenCopyModal(false)
                  }}
                />
              )}
              {openNotesModal && (
                <NotesModal
                  title={`Annotazioni bilancio ${format(
                    new Date(bilancio.data_bilancio),
                    "dd/MM/yyyy"
                  )}`}
                  initialValue={bilancio.note || ""}
                  onSave={nextNote => {
                    return save({ ...bilancio, note: nextNote }, {})
                  }}
                  toggle={() => setOpenNotesModal(false)}
                />
              )}
              <XbrlWarnModal
                isOpen={openXbrlWarnModal}
                toggle={toggleXbrlWarnModal}
              />
            </form>
            {get(values, "__meta.toSave", false) && (
              <>
                <div
                  className="position-sticky rounded pb-2"
                  style={{
                    bottom: 0,
                    zIndex: 90,
                    margin: "0 auto",
                    width: 200
                  }}
                >
                  <button
                    type="submit"
                    className="btn btn-success btn-lg w-100"
                    disabled={isSubmitting}
                    onClick={handleSubmit}
                  >
                    {isSubmitting ? (
                      <Spinner size="sm" />
                    ) : (
                      <span className="d-inline-flex align-items-center">
                        <FaSave className="mr-1" />
                        {"Salva"}
                      </span>
                    )}
                  </button>
                </div>
              </>
            )}
          </>
        )}
      </Formik>
    )
  )
}
