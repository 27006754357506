import { useCallback } from 'react'
import { rj, useRj } from 'react-rocketjump'
import { saveAs } from 'file-saver'
import { reportApi } from '../api'

const ReportState = rj({
  name: 'Report',
  effectCaller: rj.configured(),
  effect: t => bilancioId => reportApi(t, bilancioId),
})

export function useGenerateReport() {
  const [state, { run }] = useRj(ReportState)

  const generateReport = useCallback(bilancioId => {
    return run
      .onSuccess(([blobPdf, filename]) => {
        saveAs(blobPdf, filename.replace(/"/g, ''))
      })
      .asPromise(bilancioId)
  }, [run])

  return [generateReport, state]
}
