import React from "react";
import { Formik, Field } from "formik";
import { InputField, DateField } from "../../components/Fields";
import { ModalHeader, ModalBody, ModalFooter } from "reactstrap";

const initialEmpty = {
  azienda: "",
  data_bilancio: "",
  note: ""
};

export default function BilancioForm({
  initialValues = initialEmpty,
  title = "Nuovo bilancio",
  toggleModal,
  save,
  onSuccess
}) {
  return (
    <Formik
      onSubmit={(values, actions) => {
        return save(values)
          .then((result) => {
            actions.setSubmitting(false);
            toggleModal();
            if (typeof onSuccess === "function") {
              onSuccess(result)
            }
          })
          .catch(err => {
            actions.setErrors(err.response);
            actions.setSubmitting(false);
          });
      }}
      initialValues={initialValues}
    >
      {({
        handleSubmit,
        isSubmitting
      }) => {
        return (
          <>
            <form onSubmit={handleSubmit}>
              <ModalHeader toggle={toggleModal} className="bg-primary text-white">
                {title}
              </ModalHeader>
              <ModalBody>
                <Field
                  rowLayout={false}
                  label="Data bilancio"
                  disabled={initialValues !== initialEmpty}
                  name="data_bilancio"
                  component={DateField}
                />

                <Field
                  rowLayout={false}
                  type="text"
                  label="Descrizione breve"
                  name="descrizione"
                  component={InputField}
                />
              </ModalBody>

              <ModalFooter className="justify-content-between">
                <button
                  type="button"
                  className="btn btn-light"
                  onClick={toggleModal}
                >
                  Annulla
                </button>
                <button
                  className="btn btn-success"
                  type="submit"
                  disabled={isSubmitting}
                >
                  Salva
                </button>
              </ModalFooter>
            </form>
          </>
        );
      }}
    </Formik>
  );
}
