import React from "react"
import get from "lodash/get"
import sortBy from "lodash/sortBy"
import { CARD_CLASSES } from "../constants"

const ThresholdsTable = ({ indicatorCode, thresholds, ...props }) => {
  const indicatorThreshold = sortBy(
    get(thresholds, indicatorCode, []),
    item => parseFloat(get(item, "min", -Infinity))
  )

  if (indicatorThreshold.length === 0) {
    return null
  }

  const getClass = score => {
    return CARD_CLASSES.find(item => item.from <= score && score < item.to).className
  }

  return (
    <table className="table table-sm striped bordered">
      <thead>
        <tr>
          <th>Intervallo</th>
          <th>Punteggio</th>
        </tr>
      </thead>
      <tbody>
        {indicatorThreshold.map((t, i) => (
          <tr key={i}>
            <td>{t.label}</td>
            <td className="p-0">
              <div className={`w-100 h-100 ${getClass(t.score)} p-1`}>
                {t.score_label ?? parseFloat(t.score).toFixed(2)}
              </div>
            </td>
          </tr>
        ))}
      </tbody>
    </table>
  )
}

export default ThresholdsTable