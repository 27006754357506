import React, { useState, useContext } from "react"
import Header from "../../components/Header"
import Menu from "../../components/Menu"
import Layout from "react-drawers"
import { Link } from "react-router-dom"
import { deps } from "react-rocketjump"
import { BilanciListState } from "../../localstate/bilanci"
import { useQsDataTable } from "react-use-datatable"
import Paginator from "../../components/Paginator"
import { Modal } from "reactstrap"
import BilancioForm from "./BilancioForm"
import { AziendaContext } from "../../providers"
import { FaLockOpen, FaLock } from "react-icons/fa"
import { format } from "date-fns"
import WithConfirmation from "../../components/ConfirmationModal"
import { FaPlus } from "react-icons/fa"
import moment from "moment"
import { Onboarding } from "../../components/Onboarding"

const DangerousButton = WithConfirmation("button")

const Bilanci = ({ history }) => {
  const { currentAzienda } = useContext(AziendaContext)

  const [
    { bilanci, pagination },
    { page },
    { goToPage, addBilancio, update, deleteBilancio, run }
  ] = useQsDataTable(
    BilanciListState,
    {
      search: {
        field: "search",
        value: undefined
      }
    },
    [deps.maybeGet(currentAzienda, "id")]
  )

  const [adding, setAdding] = useState(false)
  const [editing, setEditing] = useState(null)
  const toggleAdding = () => setAdding(!adding)

  const addBilancioForCurrentAzienda = payload =>
    addBilancio.asPromise({
      ...payload,
      azienda: currentAzienda.id
    })

  return (
    <>
      <Layout absolute className="classname-applied-to-content">
        <Layout.Top className="top-classname">
          <Header></Header>
        </Layout.Top>
        <Layout.Left className="left-classname">
          <Menu></Menu>
        </Layout.Left>
        <Layout.Right className="right-classname"></Layout.Right>

        <Layout.Content className="h-100 overflow-auto with-sticky-footer">
          <div className="sticky-header bg-light p-3">
            <div className="d-flex justify-content-between align-items-center">
              <h4 className="m-0">Bilanci</h4>

              {currentAzienda && (
                <div className="">
                  <div className="input-group mr-2"></div>
                  <button
                    onClick={toggleAdding}
                    className="btn btn-primary btn-sm"
                  >
                    <span className="d-inline-flex align-items-center">
                      <FaPlus className="mr-2" />
                      Aggiungi bilancio
                    </span>
                  </button>
                </div>
              )}
            </div>
          </div>

          <div className="p-3">
            {!currentAzienda && (
              <div className="text-center">Selezionare un'azienda.</div>
            )}

            {currentAzienda && bilanci && (
              <div>
                <table className="table table-striped">
                  <thead>
                    <tr>
                      <th>Data bilancio (consolidato)</th>
                      <th></th>
                      <th></th>
                      <th></th>
                    </tr>
                  </thead>
                  <tbody>
                    {bilanci.map(bilancio => (
                      <tr key={bilancio.id}>
                        <td className="w-50">
                          <span className="d-inline-flex align-items-center">
                            {moment(bilancio.data_bilancio).format(
                              "DD/MM/YYYY"
                            )}
                            {bilancio.consolidato && (
                              <FaLock className="ml-2" />
                            )}
                            {!bilancio.consolidato && (
                              <FaLockOpen className="ml-2" />
                            )}
                          </span>
                          <br />
                          <small>
                            <i>{bilancio.descrizione}</i>
                          </small>
                        </td>

                        <td>
                          <Link to={`/bilanci/${bilancio.id}`}>
                            Apri bilancio
                          </Link>
                        </td>

                        {!bilancio.bilancio_chiusura && (
                          <td>
                            <Link to={`/bilanci/${bilancio.id}/proiezione`}>
                              Apri proiezione a fine esercizio
                            </Link>
                          </td>
                        )}
                        {bilancio.bilancio_chiusura && <td />}
                        <td>
                          <div style={{ marginBottom: "-0.25rem" }}>
                            <button
                              className="btn btn-primary btn-sm mr-2 mb-1"
                              onClick={() => setEditing(bilancio)}
                              disabled={bilancio.consolidato}
                            >
                              {"Modifica descrizione"}
                            </button>
                            {bilancio.consolidato && (
                              <button
                                className="btn btn-primary btn-sm mr-2 mb-1"
                                onClick={() =>
                                  update(bilancio.id, { consolidato: false })
                                }
                              >
                                {"Sblocca"}
                              </button>
                            )}
                            {!bilancio.consolidato && (
                              <button
                                className="btn btn-primary btn-sm mr-2 mb-1"
                                onClick={() =>
                                  update(bilancio.id, { consolidato: true })
                                }
                              >
                                {"Consolida"}
                              </button>
                            )}
                            {!bilancio.consolidato && (
                              <DangerousButton
                                className="btn btn-danger btn-sm mr-2 mb-1"
                                caption={
                                  "Conferma eliminazione del bilancio " +
                                  format(
                                    new Date(bilancio.data_bilancio),
                                    "dd/MM/yyyy"
                                  ) +
                                  "?"
                                }
                                headerClassName="bg-danger text-white"
                                onClick={() => {
                                  deleteBilancio
                                    .onSuccess(() =>
                                      run(currentAzienda.id, { page })
                                    )
                                    .run(bilancio.id)
                                }}
                              >
                                {"Elimina"}
                              </DangerousButton>
                            )}
                          </div>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            )}
          </div>

          <div className="sticky-footer bg-light border-top">
            <Paginator
              page={page}
              pagination={pagination}
              goToPage={goToPage}
            ></Paginator>
          </div>
        </Layout.Content>

        <Modal isOpen={adding} size="lg">
          {currentAzienda && (
            <BilancioForm
              title={`Nuovo bilancio per ${currentAzienda.ragione_sociale}`}
              save={values => addBilancioForCurrentAzienda(values)}
              toggleModal={toggleAdding}
              onSuccess={bilancio => {
                history.push(`/bilanci/${bilancio.id}`)
              }}
            ></BilancioForm>
          )}
        </Modal>

        <Modal isOpen={editing !== null} size="lg">
          {editing && (
            <BilancioForm
              title={`Modifica bilancio ${format(
                new Date(editing.data_bilancio),
                "dd/MM/yyyy"
              )}`}
              initialValues={editing}
              save={values =>
                update
                  .onSuccess(() => setEditing(null))
                  .asPromise(editing.id, values)
              }
              toggleModal={() => setEditing(null)}
            />
          )}
        </Modal>
      </Layout>
      <Onboarding />
    </>
  )
}

export default Bilanci
