import React from "react"
import { useRunRj, deps } from "react-rocketjump"
import { AnnotazioneIndicatoriState } from "../../localstate/bilanci"
import ReportHeader from "./Header"
import IndicatorCard from "./IndicatorCard"
import sortBy from "lodash/sortBy"
import get from "lodash/get"

const ICASPage = props => {
  const { bilancio, schemaIndicatori, thresholds, indicatorsStatics, indicatoriIcas: indicatori } = props
  const [{ data: notes }] = useRunRj(AnnotazioneIndicatoriState, [deps.maybeGet(bilancio, "id"), "ICAS"])

  if (!indicatori) {
    return null
  }

  const ICASIndicators = schemaIndicatori["ICAS"].indicators

  return (
    <>
      <div className="page indicator-page ida-page">
        <ReportHeader {...props} />
        <h1>ALTRI INDICI</h1>
        <h2>Indici Sintetici di Continuità Aziendale</h2>
        <div className="under_title">
          (Indici estratti dal “Quaderno SAF 71” pubblicato dall’Ordine dei Dottori Commercialisti ed Esperti Contabili di Milano)<br />
          Set di indici di bilancio rappresentanti anomalie rilevanti che possono rappresentare “campanelli di allarme “tali da rendere necessaria una verifica più approfondita della tenuta della <b>continuità aziendale</b>”.
        <ul>
            <li>ICA 1 = si verifica se le perdite d’esercizio riducono di oltre il 50% il Patrimonio Netto.</li>
            <li>ICA 2 = si verifica se il Patrimonio netto rettificato dalle immobilizzazioni immateriali è negativo.</li>
            <li>ICA 3 = si verifica se il fatturato da un anno all’altro si riduce di oltre il 10%</li>
            <li>ICA 4 = si verifica se il capitale circolante lordo “operativo” sale molto di più dell’autofinanziamento operativo= (MOL +/- accantonamento utilizzi fondi rischi +oneri diversi –ricavi diversi e immobilizzazioni interne)</li>
            <li>ICA 5 = si verifica se l’autofinanziamento operativo +è negativo</li>
            <li>ICA 6 = si verifica se la Posizione Finanziaria Netta (totale debiti finanziari- disponibilità liquide) cresce in maniera significativa rispetto all’indice di indipendenza finanziari = Capitale netto tangibile / Capitale investito).</li>
            <li>ICA 7 = si verifica se il Capitale netto/totale attivo è {"<"} 5% e utile ante imposte/ricavi {"<"} 1%</li>
            <li>ICA 8 = si verifica se l’incidenza degli oneri finanziari sul MOL è {">"} 30%</li>
          </ul>
        </div>
        <div className="grid-3x3">
          {sortBy(indicatori, "codice").filter(i => !!ICASIndicators[i.codice]).map(indicatorValue => (
            <IndicatorCard
              indicatorsStatics={indicatorsStatics}
              key={indicatorValue.codice}
              thresholds={thresholds}
              indicatorName={indicatorValue.codice}
              indicatorDescription={ICASIndicators[indicatorValue.codice]}
              indicatorValue={indicatorValue}
            />
          ))}
        </div>
      </div>
      {get(notes, "note", undefined) && (
        <div className="page indicator-page ida-page">
          <ReportHeader {...props} />
          <h1>ALTRI INDICI</h1>
          <h2>Indici Sintetici di Continuità Aziendale</h2>
          <h2>Annotazioni</h2>
          <p className="note">{get(notes, "note", null)}</p>
        </div>
      )}
    </>
  )

}

export default ICASPage