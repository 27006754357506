import { rj } from "react-rocketjump"
import rjList, {
  nextPreviousPaginationAdapter
} from "react-rocketjump/plugins/list"
import {
  aziendeApi,
  aziendaApi,
  aziendaCreateApi,
  aziendaUpdateApi,
  aziendaDeleteApi
} from "../api"
import sortBy from "lodash/sortBy"

export const AziendeListState = rj(
  rjList({
    pageSize: 50,
    pagination: nextPreviousPaginationAdapter
  }),
  {
    name: "aziende",

    effectCaller: rj.configured(),
    effect: t => params => aziendeApi(t, params),

    mutations: {
      addAzienda: rj.mutation.single({
        effectCaller: rj.configured(),
        effect: t => payload => aziendaCreateApi(t, payload),
        updater: (state, azienda) => {
          return {
            ...state,
            data: {
              ...state.data,
              list: sortBy(state.data.list.concat(azienda), "ragione_sociale")
            }
          };
        }
      }),
      deleteAzienda: rj.mutation.single({
        effect: t => id => aziendaDeleteApi(t, id),
        updater: (state, deletedId) => {
          return {
            ...state,
            data: {
              ...state.data,
              list: state.data.list.filter(item => item.id !== deletedId)
            }
          }
        }
      })
    },

    computed: {
      aziende: "getList",
      aziendeLoading: "isLoading",
      aziendaSaving: "@mutation.addAzienda.pending"
    }
  }
);

export const AziendaDetailState = rj({
  name: "azienda",
  effectCaller: rj.configured(),
  effect: t => (id, query) => aziendaApi(t, id, query),

  mutations: {
    updateAzienda: rj.mutation.single({
      effectCaller: rj.configured(),
      effect: t => (id, payload) => aziendaUpdateApi(t, id, payload),
      updater: (state, azienda) => {
        return {
          ...state,
          data: azienda
        };
      }
    }),
    deleteAzienda: rj.mutation.single({
      effect: t => id => aziendaDeleteApi(t, id),
      updater: (state, deletedId) => {
        return {
          ...state,
          data: null
        }
      }
    })
  },

  computed: {
    azienda: "getData",
    aziendaSaving: "@mutation.updateAzienda.pending"
  }
});
