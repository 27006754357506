import React from "react"
import ReactDOM from "react-dom"
import "./styles/index.scss"

import App from "./App"
import * as serviceWorker from "./serviceWorker"
import * as Sentry from "@sentry/react"
import logger from "react-rocketjump/logger"
import { BrowserTracing } from "@sentry/tracing"

logger()

if (
  process.env.NODE_ENV === "production" &&
  process.env.REACT_APP_SENTRY_RELEASE
) {
  Sentry.init({
    dsn:
      "https://f6a9dfe4b1df4a979f4db5bd5e222118@o1047151.ingest.sentry.io/4503931486994432",
    integrations: [new BrowserTracing()],

    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    // We recommend adjusting this value in production
    tracesSampleRate: 0.0
  })
}

ReactDOM.render(<App />, document.getElementById("root"))

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister()
