import React from "react"
import { Modal, ModalHeader, ModalBody, ModalFooter, Button, Spinner } from "reactstrap"
import { Formik, Field } from "formik"
import { FileField } from "../../components/Fields"

const XbrlImportModal = ({ xbrlImport, isOpen, toggle }) => {
  return (
    <Formik
      initialValues={{ xbrl_file: undefined }}
      onSubmit={(values, actions) => {
        xbrlImport(values.xbrl_file).then(() => {
          actions.setSubmitting(false)
          actions.resetForm()
        }).catch(err => {
          actions.setSubmitting(false)
          actions.setFieldError("xbrl_file", err.response.xbrl_file || err.response.detail)
        })
      }}
    >
      {({ handleSubmit, handleReset, isSubmitting }) => {

        const formAwareToggle = () => {
          handleReset()
          toggle()
        }

        return (
          <Modal isOpen={isOpen} toggle={formAwareToggle}>
            <ModalHeader toggle={formAwareToggle} className="bg-primary text-white">Importa bilancio XBRL</ModalHeader>
            <ModalBody>
              <p>Attenzione: l'importazione sovrascrive qualsiasi dato precedentemente inserito</p>
              <Field
                name="xbrl_file"
                label="File XBRL"
                rowLayout
                component={FileField}
              />
            </ModalBody>
            <ModalFooter>
              <div className="w-100 h-100 d-flex flex-row justify-content-between">
                <Button disabled={isSubmitting} color="secondary" type="button" onClick={() => {
                  formAwareToggle()
                }}
                >Annulla</Button>
                <Button disabled={isSubmitting} color="success" type="button" onClick={handleSubmit}>
                  <span className="mx-1">Salva</span>
                  {isSubmitting && <Spinner size="sm" />}
                </Button>
              </div>
            </ModalFooter>
          </Modal>
        )
      }}
    </Formik>
  )
}

export default XbrlImportModal