import React from "react"
import { Switch, Route, Link } from "react-router-dom"

const getUrls = bilancio =>
  [
    {
      name: "Bilancio",
      url: `/bilanci/${bilancio.id}`,
      exact: true
    },
    {
      name: "Proiezione",
      url: `/bilanci/${bilancio.id}/proiezione`,
      when: bilancio =>
        bilancio.quadratura === 0 &&
        bilancio.voci.find(v => parseFloat(v.rettifica || v.valore) !== 0) &&
        !bilancio.bilancio_chiusura
    },
    {
      name: "1. Test risanamento",
      url: `/bilanci/${bilancio.id}/risanamento`,
      when: bilancio =>
        bilancio.quadratura === 0 &&
        bilancio.voci.find(v => parseFloat(v.rettifica || v.valore) !== 0)
    },
    {
      name: "2. Indicatori consuntivi",
      url: `/bilanci/${bilancio.id}/indicatori`,
      when: bilancio =>
        bilancio.quadratura === 0 &&
        bilancio.voci.find(v => parseFloat(v.rettifica || v.valore) !== 0)
    },
    {
      name: "3. Indicatori previsionali",
      url: `/bilanci/${bilancio.id}/previsione`,
      when: bilancio =>
        !bilancio.bilancio_chiusura &&
        bilancio.quadratura === 0 &&
        bilancio.voci.find(v => parseFloat(v.rettifica || v.valore) !== 0)
    },
    {
      name: "4. Altri indicatori",
      url: `/bilanci/${bilancio.id}/altro`,
      when: bilancio =>
        bilancio.quadratura === 0 &&
        bilancio.voci.find(v => parseFloat(v.rettifica || v.valore) !== 0)
    },
    {
      name: "Report",
      url: `/bilanci/${bilancio.id}/stampa-report`,
      when: bilancio =>
        bilancio.quadratura === 0 &&
        bilancio.voci.find(v => parseFloat(v.rettifica || v.valore) !== 0)
    }
  ].filter(item => !item.hide || !item.hide(bilancio))

const BilancioLandmarks = ({ bilancio }) => {
  const urls = getUrls(bilancio)

  return (
    <ul className="nav nav-pills justify-content-end">
      {urls.map(({ url, name, exact, when }, i) => {
        const enabled = !when || when(bilancio)
        return (
          <li className="nav-item" key={i}>
            <Switch>
              <Route path={url} exact={!!exact}>
                <Link
                  className={`nav-link active bg-dark ${
                    enabled ? "" : "disabled"
                  }`}
                  to={url}
                  style={{ padding: "0.5rem 1rem" }}
                >
                  {name}
                </Link>
              </Route>
              <Route>
                <Link
                  className={`nav-link ${enabled ? "" : "disabled"}`}
                  to={url}
                  style={{ padding: "0.5rem 1rem" }}
                >
                  {name}
                </Link>
              </Route>
            </Switch>
          </li>
        )
      })}
    </ul>
  )
}

export default BilancioLandmarks
