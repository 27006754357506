import { rj } from "react-rocketjump";
import { configApi } from "../api";

export const ConfigState = rj({
  name: "config",
  effectCaller: rj.configured(),
  effect: t => () => configApi(t),
  computed: {
    config: "getData"
  }
});
