import React from "react"
import get from "lodash/get"
import "./IndicatorCard.scss"
import IndicatorBar from "../../components/IndicatorBar/IndicatorBar"
import { CARD_CLASSES } from "../../constants"
import IndicatorValue from "../../components/IndicatorValue"

const IndicatorCard = ({ indicatorName, indicatorDescription, indicatorValue, thresholds, indicatorsStatics, noScore = false }) => {
  const score = indicatorValue.punteggio
  const scoreClass = score === null
    ? 'indicator-no-value'
    : get(CARD_CLASSES.find(item => item.from <= score && score < item.to), "className")

  const statics = get(indicatorsStatics, indicatorName, {})

  return (
    <div className={`indicator-card ${scoreClass}`} key={indicatorName}>
      <p className="indicator-name">{get(statics, "descrizione_breve", indicatorDescription.name)}</p>
      <p className="indicator-value">
        <IndicatorValue
          indicatorDescription={indicatorDescription}
          rawValue={indicatorValue.valore}
        />
      </p>
      {!noScore && (
        <IndicatorBar
          enableTexts={false}
          indicatorCode={indicatorName}
          indicatorDescription={indicatorDescription}
          thresholds={thresholds}
          computedValue={indicatorValue.valore}
        />
      )}
    </div>
  )
}

export default IndicatorCard