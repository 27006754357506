import React from "react"
import { FiMinusSquare, FiPlusSquare } from "react-icons/fi"
import { Spinner } from "reactstrap"

const BilancioProjectionFooter = ({
  collapseAll,
  expandAll,
  quadratura,
  showCopyModal,
  showXbrlModal,
  readOnly,
  performAutoProjection,
  isPerformingProjection
}) => {
  return (
    <div className="d-flex justify-content-between align-items-center w-100">
      <div className="d-flex flex-row justify-content-start align-items-center">
        {quadratura !== 0 && (
          <span
            className="d-inline-block badge badge-danger p-2"
            style={{ fontSize: "90%" }}
          >
            <b>Squadratura: </b>
            <span>{quadratura}</span>
            <b> €</b>
          </span>
        )}
        {quadratura === 0 && (
          <span
            className="d-inline-block badge badge-success p-2"
            style={{ fontSize: "90%" }}
          >
            <b>Squadratura: </b>
            <span>{quadratura}</span>
            <b> €</b>
          </span>
        )}
      </div>
      <div className="d-flex flex-row align-items-center justify-content-center w-50">
        <button
          type="button"
          onClick={collapseAll}
          className="btn btn-sm btn-light mr-1"
        >
          <FiMinusSquare />
          <span className="ml-1">Riduci tutto</span>
        </button>
        <button
          type="button"
          onClick={expandAll}
          className="btn btn-sm btn-light mr-1"
        >
          <FiPlusSquare />
          <span className="ml-1">Espandi tutto</span>
        </button>
      </div>
      <div className="d-flex flex-row jusfity-content-end align-items-center">
        {performAutoProjection && (
          <button
            type="button"
            onClick={performAutoProjection}
            disabled={readOnly || isPerformingProjection}
            className="btn btn-sm btn-primary mr-1"
          >
            {"Calcola automaticamente"}
            {isPerformingProjection && <Spinner size="sm" className="ml-1" />}
          </button>
        )}
        <button
          type="button"
          onClick={showXbrlModal}
          disabled={readOnly}
          className="btn btn-sm btn-primary mr-1"
        >
          {"Importa XBRL"}
        </button>
        <button
          type="button"
          onClick={showCopyModal}
          disabled={readOnly}
          className="btn btn-sm btn-primary mr-1"
        >
          {"Copia da bilancio"}
        </button>
      </div>
    </div>
  )
}

export default BilancioProjectionFooter
