import React from "react";
import { MdKeyboardArrowRight, MdKeyboardArrowLeft } from "react-icons/md";

export default function Paginator({ page, pagination, goToPage }) {
  return (
    <div className="w-100">
      <div className="row">
        <div className="col-sm-5 text-right">
          {page > 1 && pagination.numPages > 0 && (
            <button
              type="button"
              className="btn btn-sm btn-primary"
              onClick={() => {
                goToPage(page - 1);
              }}
            >
              <MdKeyboardArrowLeft />
            </button>
          )}
        </div>
        <div className="col-sm-2 text-center">
          Pagina {page} di {Math.max(1, pagination.numPages)}
        </div>
        <div className="col-sm-5 text-left">
          {page < pagination.numPages && (
            <button
              className="btn btn-sm btn-primary"
              type="button"
              onClick={() => {
                goToPage(page + 1);
              }}
            >
              <MdKeyboardArrowRight />
            </button>
          )}
        </div>
      </div>
    </div>
  );
}
