import React, { useCallback, useEffect, useState } from "react"
import Logo from "../assets/logo-4-check-white.png"
import Slide1 from "../assets/onboarding/Slide1.png"
import Slide2 from "../assets/onboarding/Slide2.png"
import Slide3 from "../assets/onboarding/Slide3.png"
import Slide4 from "../assets/onboarding/Slide4.png"
import classnames from "classnames"
import { Button } from "reactstrap"
import { FaTimes } from "react-icons/fa"

let DISPLAY = true

function Footer({
  index,
  onChange,
  onHide,
  noPrev = false,
  noNext = false,
  start = false,
  onStart
}) {
  return (
    <div className="slide-actions">
      <div className="slide-actions-left">
        {!noPrev && (
          <Button
            size="sm"
            outline
            color="primary"
            className="mr-2"
            onClick={() => onChange(index - 1)}
          >
            Precedente
          </Button>
        )}
        {!noNext && (
          <Button size="sm" color="primary" onClick={() => onChange(index + 1)}>
            Successivo
          </Button>
        )}
        {start && (
          <Button size="sm" color="primary" onClick={() => onStart()}>
            Inizia
          </Button>
        )}
      </div>
      <div className="slide-actions-right">
        <div
          className="pointer"
          onClick={() => {
            onHide()
          }}
        >
          <small>
            <i>
              <u>Non mostrare più</u>
            </i>
          </small>
        </div>
      </div>
    </div>
  )
}

export function Onboarding() {
  const [curr, setCurr] = useState(0)
  const [show, setShow] = useState(false)

  useEffect(() => {
    const shouldSkip = localStorage.getItem("4check-skip-tutorial")
    setShow(shouldSkip !== "yes" && DISPLAY)
  }, [])

  const handleHide = useCallback(() => {
    setShow(false)
    localStorage.setItem("4check-skip-tutorial", "yes")
    DISPLAY = false
  }, [])

  const handleStart = useCallback(() => {
    setShow(false)
    DISPLAY = false
  }, [])

  return (
    <div
      className="onboarding-root"
      style={{ opacity: show ? 1 : 0, pointerEvents: show ? "all" : "none" }}
    >
      <div className="slides">
        <div
          className={classnames("slide", {
            "slide-past": curr > 0,
            "slide-future": curr < 0
          })}
        >
          <div className="slide-content">
            <img src={Logo} alt="" className="onboarding-logo" />
          </div>
          <div className="slide-message">
            <h4 className="mb-0">
              Benvenuto in <span className="text-primary">4 Check Impresa</span>
            </h4>
            <p>Scopri come utilizzarlo in quattro semplici passaggi.</p>
          </div>
          <div className="onboarding-close" onClick={handleStart}>
            <FaTimes />
          </div>
          <Footer
            index={0}
            noPrev
            onChange={setCurr}
            onHide={handleHide}
            onStart={handleStart}
          />
        </div>
        <div
          className={classnames("slide", {
            "slide-past": curr > 1,
            "slide-future": curr < 1
          })}
        >
          <div className="slide-content">
            <img src={Slide1} alt="" className="onboarding-image" />
          </div>
          <div className="slide-message">
            <h4 className="mb-0">
              <span className="text-primary">1. Aggiungi un'azienda</span>
            </h4>
            <p>
              Indica per ciascuna la ragione sociale, il settore e la data di
              chiusura del prossimo esercizio.
            </p>
          </div>
          <div className="onboarding-close" onClick={handleStart}>
            <FaTimes />
          </div>
          <Footer
            index={1}
            onChange={setCurr}
            onHide={handleHide}
            onStart={handleStart}
          />
        </div>
        <div
          className={classnames("slide", {
            "slide-past": curr > 2,
            "slide-future": curr < 2
          })}
        >
          <div className="slide-content">
            <img src={Slide2} alt="" className="onboarding-image" />
          </div>
          <div className="slide-message">
            <h4 className="mb-0">
              <span className="text-primary">2. Carica i bilanci</span>
            </h4>
            <p>
              Clicca su "Visualizza bilanci" e carica i loro bilanci annuali o
              infrannuali.
            </p>
          </div>
          <div className="onboarding-close" onClick={handleStart}>
            <FaTimes />
          </div>
          <Footer
            index={2}
            onChange={setCurr}
            onHide={handleHide}
            onStart={handleStart}
          />
        </div>
        <div
          className={classnames("slide", {
            "slide-past": curr > 3,
            "slide-future": curr < 3
          })}
        >
          <div className="slide-content">
            <img src={Slide3} alt="" className="onboarding-image" />
          </div>
          <div className="slide-message">
            <h4 className="mb-0">
              <span className="text-primary">3. Aggiungi i bilanci</span>
            </h4>
            <p>
              Aggiungi il bilancio importando i dati con file xbrl, copiandolo
              da un altro bilancio o inserendoli manualmente.
            </p>
          </div>
          <div className="onboarding-close" onClick={handleStart}>
            <FaTimes />
          </div>
          <Footer index={3} onChange={setCurr} onHide={handleHide} />
        </div>
        <div
          className={classnames("slide", {
            "slide-past": curr > 4,
            "slide-future": curr < 4
          })}
        >
          <div className="slide-content">
            <img src={Slide4} alt="" className="onboarding-image" />
          </div>
          <div className="slide-message">
            <h4 className="mb-0">
              <span className="text-primary">4. Analizza</span>
            </h4>
            <p>
              Scopri lo stato di salute delle tue aziende grazie ai{" "}
              <b>4 check</b>.
            </p>
          </div>
          <div className="onboarding-close" onClick={handleStart}>
            <FaTimes />
          </div>
          <Footer
            index={4}
            noNext
            start
            onChange={setCurr}
            onHide={handleHide}
            onStart={handleStart}
          />
        </div>
      </div>
    </div>
  )
}
