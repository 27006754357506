import { useContext } from "react"
import Layout from "react-drawers"
import { GoThreeBars } from "react-icons/go"
import { MdClose } from "react-icons/md"
import { withRouter } from "react-router-dom"
import { useAuthUser } from "use-eazy-auth"
import LogoIndicatoriAllerta from "../assets/logo-4-check-white.png"
import { AziendaContext, ConfigContext } from "../providers"
import CustomIcon from "./CustomIcon"

function Header({ history }) {
  const { user } = useAuthUser()
  const { currentAzienda, setCurrentAzienda } = useContext(AziendaContext)
  const config = useContext(ConfigContext)

  return (
    <nav className="navbar navbar-expand sticky-top navbar-dark bg-primary shadow-sm">
      <div className="">
        <span
          to="/"
          className="navbar-brand d-flex flex-row align-items-center"
        >
          {user && (
            <Layout.Switch left>
              {({ open, toggle }) => (
                <div style={{ width: 40, paddingLeft: 2 }} className="">
                  <GoThreeBars
                    onClick={toggle}
                    className="text-white pointer"
                    size={24}
                  />
                </div>
              )}
            </Layout.Switch>
          )}
          <img
            src={LogoIndicatoriAllerta}
            alt="Indicatori di Allerta"
            style={{
              marginTop: "-0.5rem",
              marginBottom: "-0.5rem",
              maxHeight: "2.3rem",
              maxWidth: "400px"
            }}
          />
          {currentAzienda && (
            <span
              title={`Azienda corrente: ${currentAzienda.ragione_sociale}`}
              className="badge badge-dark d-inline-flex align-items-center"
              style={{ marginLeft: 20 }}
            >
              <CustomIcon icon="azienda" size={14} className="mr-2" />
              <span className="d-inline-flex flex-row align-items-center">
                <span>{currentAzienda.ragione_sociale}</span>
                {config !== null && (
                  <small className="ml-2">
                    {
                      config.settori.find(
                        settore => settore[0] === currentAzienda.settore
                      )[1]
                    }
                  </small>
                )}
              </span>
              <MdClose
                title="Chiudi azienda"
                onClick={() => {
                  setCurrentAzienda(null)
                  history.push("/aziende")
                }}
                className="ml-2 pointer"
              />
            </span>
          )}
        </span>
      </div>
    </nav>
  )
}

export default withRouter(Header)
