import { rj } from "react-rocketjump"
import { commercialistaApi, commercialistaUpdateApi } from "../api"

export const CommercialistaState = rj({
  effectCaller: rj.configured(),
  effect: token => commercialistaId => commercialistaApi(token, commercialistaId),
  mutations: {
    update: rj.mutation.single({
      effect: token => (commercialistaId, data) => 
        commercialistaUpdateApi(token, commercialistaId, data),
      updater: "updateData"
    })
  }
})
