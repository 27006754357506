import React from "react"
import { FiMinusSquare, FiPlusSquare } from "react-icons/fi"

const BilancioEditorFooter = ({
  expandAll,
  collapseAll,
  quadratura,
  showXbrlModal,
  showCopyModal,
  readOnly
}) => {
  return (
    <div className="d-flex justify-content-between align-items-center w-100">
      <div className="d-flex flex-row justify-content-start align-items-center w-25">
        {quadratura !== 0 && (
          <span
            className="d-inline-block badge badge-danger p-2"
            style={{ fontSize: "90%" }}
          >
            <b>Squadratura: </b>
            <span>{quadratura}</span>
            <b> €</b>
          </span>
        )}
        {quadratura === 0 && (
          <span
            className="d-inline-block badge badge-success p-2"
            style={{ fontSize: "90%" }}
          >
            <b>Squadratura: </b>
            <span>{quadratura}</span>
            <b> €</b>
          </span>
        )}
      </div>
      <div className="d-flex flex-row align-items-center justify-content-center w-50">
        <button
          type="button"
          onClick={collapseAll}
          className="btn btn-sm btn-light mr-1"
        >
          <FiMinusSquare />
          <span className="ml-1">Riduci tutto</span>
        </button>
        <button
          type="button"
          onClick={expandAll}
          className="btn btn-sm btn-light mr-1"
        >
          <FiPlusSquare />
          <span className="ml-1">Espandi tutto</span>
        </button>
      </div>
      <div className="d-flex flex-row justify-content-end align-items-center w-25">
        <button
          type="button"
          onClick={showXbrlModal}
          disabled={readOnly}
          className="btn btn-sm btn-primary mr-1"
        >
          {"Importa XBRL"}
        </button>
        <button
          type="button"
          onClick={showCopyModal}
          disabled={readOnly}
          className="btn btn-sm btn-primary mr-1"
        >
          {"Copia da bilancio"}
        </button>
      </div>
    </div>
  )
}

export default BilancioEditorFooter
