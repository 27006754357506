import React, { useState } from "react"
import Layout from "react-drawers"
import { useRunRj } from "react-rocketjump"
import { Link } from "react-router-dom"
import Header from "../../components/Header"
import Menu from "../../components/Menu"
import { BilancioDetailState } from "../../localstate/bilanci"
import BilancioEditor from "./BilancioEditor"
import { Route } from "react-router-dom"
import IndicatoriBilancio from "./IndicatoriBilancio"
import TestRisanamento from "./TestRisanamento"
import BilancioLandmarks from "./BilancioLandmarks"
import BilancioEditorCompare from "./BilancioEditorProiezione"
import StampaReport from "./StampaReport"
import moment from "moment"
import { FaAngleRight } from "react-icons/fa"
import { Onboarding } from "../../components/Onboarding"

const INDICATORI_CONSUNTIVI = ["IdA"]
const INDICATORI_PREVISIONALI = ["IdAp"]
const INDICATORI_ALTRO = ["ICAS", "FCFO", "LEVE", "BEP", "ISPI"]

const Bilancio = ({ match, history }) => {
  const { params } = match
  const [{ bilancio }, { updateBilancio }] = useRunRj(BilancioDetailState, [
    params.id
  ])

  const [editing, setEditing] = useState(false)

  const toggleEditing = () => setEditing(!editing)

  return (
    <>
      <Layout absolute>
        <Layout.Top>
          <Header></Header>
        </Layout.Top>
        <Layout.Left>
          <Menu></Menu>
        </Layout.Left>
        <Layout.Right></Layout.Right>

        <Layout.Content className="h-100 overflow-y-auto with-sticky-footer">
          <Route
            path={`${match.path}`}
            exact
            render={() => {
              if (!bilancio) {
                return null
              }
              return (
                <>
                  <div className="sticky-header bg-light p-3">
                    <div className="d-flex justify-content-between align-items-center w-100">
                      <h4 className="m-0 d-inline-flex align-items-center">
                        <Link to={`/bilanci`}>Bilanci</Link>
                        <FaAngleRight className="mx-2" />
                        {moment(bilancio.data_bilancio).format("DD/MM/YYYY")}
                      </h4>
                      <BilancioLandmarks bilancio={bilancio} />
                    </div>
                  </div>
                  <BilancioEditor
                    renderHeader={({ header }) => (
                      <div className="sticky-footer bg-light border-top p-3">
                        {header}
                      </div>
                    )}
                    save={(bilancio, values) => {
                      const vociUpdate =
                        values &&
                        Object.values(values)
                          // Discard metadata
                          .filter(voce => !!voce.codice)
                          .map(voce => ({
                            ...voce,
                            rettifica:
                              voce.rettifica === "" ? null : voce.rettifica,
                            valore: voce.valore === "" ? 0 : voce.valore
                          }))
                      const payload = {
                        ...bilancio
                      }
                      if (
                        vociUpdate &&
                        Array.isArray(vociUpdate) &&
                        vociUpdate.length > 0
                      ) {
                        payload.voci_update = vociUpdate
                      }
                      return updateBilancio.asPromise(bilancio.id, payload)
                    }}
                    bilancio={bilancio}
                    onEditClick={toggleEditing}
                  />
                </>
              )
            }}
          />
          <Route
            path={`${match.path}/indicatori`}
            render={props => {
              if (!bilancio) {
                return null
              }
              return (
                <>
                  <div className="sticky-header bg-light p-3">
                    <div className="d-flex justify-content-between align-items-center w-100">
                      <h4 className="m-0 d-inline-flex align-items-center">
                        <Link to={`/bilanci`}>Bilanci</Link>
                        <FaAngleRight className="mx-2" />
                        {moment(bilancio.data_bilancio).format("DD/MM/YYYY")}
                      </h4>
                      <BilancioLandmarks bilancio={bilancio} />
                    </div>
                  </div>
                  <IndicatoriBilancio
                    {...props}
                    bilancio={bilancio}
                    groups={INDICATORI_CONSUNTIVI}
                    renderHeader={({ items }) => (
                      <div className="sticky-footer border-top bg-light p-3">
                        <div className="d-flex flex-row justify-content-between align-items-center w-100">
                          {items}
                        </div>
                      </div>
                    )}
                  />
                </>
              )
            }}
          />

          <Route
            path={`${match.path}/proiezione`}
            exact
            render={() => {
              if (!bilancio || !bilancio.bilancio_proiezione) {
                return null
              }
              return (
                <>
                  <div className="sticky-header bg-light p-3">
                    <div className="d-flex justify-content-between align-items-center w-100">
                      <h4 className="m-0 d-inline-flex align-items-center">
                        <Link to={`/bilanci`}>Bilanci</Link>
                        <FaAngleRight className="mx-2" />
                        {moment(bilancio.data_bilancio).format("DD/MM/YYYY")}
                      </h4>
                      <BilancioLandmarks bilancio={bilancio} />
                    </div>
                  </div>
                  <BilancioEditorCompare
                    bilancioBaseId={bilancio.id}
                    bilancioFineEsercizioId={
                      bilancio.bilancio_fine_esercizio_precedente
                    }
                    bilancioTargetId={bilancio.bilancio_proiezione}
                    renderHeader={({ header }) => (
                      <div className="sticky-footer bg-light border-top p-3">
                        {header}
                      </div>
                    )}
                    makeSave={updateBilancio => (bilancio, values) => {
                      const vociUpdate =
                        values &&
                        Object.values(values)
                          // Discard metadata
                          .filter(voce => !!voce.codice)
                          .map(voce => ({
                            ...voce,
                            rettifica:
                              voce.rettifica === "" ? null : voce.rettifica,
                            valore: voce.valore === "" ? 0 : voce.valore
                          }))
                      const payload = {
                        ...bilancio
                      }
                      if (
                        vociUpdate &&
                        Array.isArray(vociUpdate) &&
                        vociUpdate.length > 0
                      ) {
                        payload.voci_update = vociUpdate
                      }
                      return updateBilancio.asPromise(bilancio.id, payload)
                    }}
                  />
                </>
              )
            }}
          />

          <Route
            path={`${match.path}/previsione`}
            render={props => {
              if (!bilancio) {
                return null
              }
              return (
                <>
                  <div className="sticky-header bg-light p-3">
                    <div className="d-flex justify-content-between align-items-center w-100">
                      <h4 className="m-0 d-inline-flex align-items-center">
                        <Link to={`/bilanci`}>Bilanci</Link>
                        <FaAngleRight className="mx-2" />
                        {moment(bilancio.data_bilancio).format("DD/MM/YYYY")}
                      </h4>
                      <BilancioLandmarks bilancio={bilancio} />
                    </div>
                  </div>
                  <IndicatoriBilancio
                    {...props}
                    bilancio={bilancio}
                    groups={INDICATORI_PREVISIONALI}
                    renderHeader={({ items }) => (
                      <div className="sticky-footer border-top bg-light p-3">
                        <div className="d-flex flex-row justify-content-between align-items-center w-100">
                          {items}
                        </div>
                      </div>
                    )}
                  />
                </>
              )
            }}
          />

          <Route
            path={`${match.path}/altro`}
            render={props => {
              if (!bilancio) {
                return null
              }
              return (
                <>
                  <div className="sticky-header bg-light p-3">
                    <div className="d-flex justify-content-between align-items-center w-100">
                      <h4 className="m-0 d-inline-flex align-items-center">
                        <Link to={`/bilanci`}>Bilanci</Link>
                        <FaAngleRight className="mx-2" />
                        {moment(bilancio.data_bilancio).format("DD/MM/YYYY")}
                      </h4>
                      <BilancioLandmarks bilancio={bilancio} />
                    </div>
                  </div>
                  <IndicatoriBilancio
                    {...props}
                    bilancio={bilancio}
                    groups={INDICATORI_ALTRO}
                    renderHeader={({ items }) => (
                      <div className="sticky-footer border-top bg-light p-3">
                        <div className="d-flex flex-row justify-content-between align-items-center w-100">
                          {items}
                        </div>
                      </div>
                    )}
                  />
                </>
              )
            }}
          />

          <Route
            path={`${match.path}/risanamento`}
            render={props => {
              if (!bilancio) {
                return null
              }
              return (
                <>
                  <div className="sticky-header bg-light p-3">
                    <div className="d-flex justify-content-between align-items-center w-100">
                      <h4 className="m-0 d-inline-flex align-items-center">
                        <Link to={`/bilanci`}>Bilanci</Link>
                        <FaAngleRight className="mx-2" />
                        {moment(bilancio.data_bilancio).format("DD/MM/YYYY")}
                      </h4>
                      <BilancioLandmarks bilancio={bilancio} />
                    </div>
                  </div>
                  <TestRisanamento
                    {...props}
                    bilancio={bilancio}
                    renderHeader={({ items }) => (
                      <div className="sticky-footer border-top bg-light p-3">
                        <div className="d-flex flex-row justify-content-between align-items-center w-100">
                          {items}
                        </div>
                      </div>
                    )}
                  />
                </>
              )
            }}
          />

          <Route
            path={`${match.path}/stampa-report`}
            exact
            render={props => <StampaReport {...props} bilancio={bilancio} />}
          />
        </Layout.Content>
      </Layout>
      <Onboarding />
    </>
  )
}

export default Bilancio
