import React, { useEffect, useCallback } from "react";
import { useAuthState } from 'use-eazy-auth'
import { useRj, useRunRj } from 'react-rocketjump'
import { ConfigState } from "../localstate/config";
import { AziendaDetailState } from "../localstate/aziende"
import ProxyStorage from "../storage";

// Smart HOC to render a component only when on authenticatd context
function wrapAuth(WrappedComponent, name) {
  WrappedComponent.displayName = name

  function Component(props) {
    const { authenticated } = useAuthState()
    if (authenticated) {
      return <WrappedComponent {...props} />
    }
    return props.children
  }

  Component.displayName = `wrapAuth(${name})`

  return Component
}

export const ConfigContext = React.createContext(null)

export const ConfigProvider = wrapAuth(({ children }) => {
  const [{ config }] = useRunRj(ConfigState, [])
  return (
    <ConfigContext.Provider value={config}>
      {children}
    </ConfigContext.Provider>
  )
}, 'ConfigProvider')

export const AziendaContext = React.createContext({
  azienda: null,
  setAzienda: () => {}
});

export const AziendaProvider = wrapAuth(({ children }) => {
  const [
    { azienda: currentAzienda },
    {
      cancel: cancelFetchAzienda,
      run: fetchAzienda,
      updateData: updateAzienda,
    }
  ] = useRj(AziendaDetailState)

  const setCurrentAzienda = useCallback((azienda) => {
    cancelFetchAzienda() // Just in case the first use effect fetch not completed ...
    updateAzienda(azienda)
    if (azienda) {
      ProxyStorage.setItem('selectedAziendaId', azienda.id)
    } else {
      ProxyStorage.removeItem('selectedAziendaId')
    }
  }, [updateAzienda, cancelFetchAzienda])

  useEffect(() => {
    const selectedAziendaId = ProxyStorage.getItem('selectedAziendaId')
    if (selectedAziendaId) {
      fetchAzienda(selectedAziendaId)
    }
  }, [fetchAzienda])

  return (
    <AziendaContext.Provider
      value={{
        setCurrentAzienda,
        currentAzienda
      }}
    >
      {children}
    </AziendaContext.Provider>
  );
}, 'AziendaProvider');
