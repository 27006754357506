import React from "react"
import { Formik, Field } from "formik"
import { InputField, DateField } from "../../components/Fields"
import { ModalHeader, ModalBody, ModalFooter } from "reactstrap"
import { useRunRj } from "react-rocketjump"
import { ConfigState } from "../../localstate/config"
import ImageField from "../../components/Fields/ImageField"

const initialEmpty = {
  ragione_sociale: "",
  data_bilancio_chiusura: "",
  settore: "",
  piva: "",
  cf: ""
}

export default function AziendaForm({
  initialValues = initialEmpty,
  title = "Nuova azienda",
  toggleModal,
  save
}) {
  const [{ config }] = useRunRj(ConfigState, [])

  return (
    <Formik
      onSubmit={(values, actions) => {
        const { logo, logo_file, ...storeValues } = values
        if (logo_file !== undefined) {
          if (logo_file !== null && logo_file instanceof File) {
            storeValues["logo"] = logo_file
          } else {
            storeValues["logo"] = new File([], "")
          }
        }
        return save(storeValues)
          .then(() => {
            actions.setSubmitting(false)
            toggleModal()
          })
          .catch(err => {
            actions.setErrors(err.response)
            actions.setSubmitting(false)
          })
      }}
      initialValues={initialValues}
    >
      {({
        values,
        errors,
        touched,
        handleChange,
        handleBlur,
        handleSubmit,
        isSubmitting
        /* and other goodies */
      }) => {
        return (
          <>
            <form onSubmit={handleSubmit}>
              <ModalHeader toggle={toggleModal} className="bg-dark text-white">
                {title}
              </ModalHeader>
              <ModalBody>
                <div className="row">
                  <div className="col-8">
                    <Field
                      rowLayout={false}
                      type="text"
                      label="Ragione sociale"
                      name="ragione_sociale"
                      component={InputField}
                    />

                    <Field
                      rowLayout={false}
                      type="select"
                      label="Settore"
                      name="settore"
                      disabled={!!initialValues.id}
                      component={InputField}
                    >
                      <option></option>
                      {config &&
                        config.settori.map(settore => (
                          <option key={settore[0]} value={settore[0]}>
                            {settore[1]}
                          </option>
                        ))}
                    </Field>

                    <Field
                      rowLayout={false}
                      type="text"
                      label="Partita IVA"
                      name="piva"
                      component={InputField}
                    />

                    <Field
                      rowLayout={false}
                      type="text"
                      label="Codice fiscale"
                      name="cf"
                      component={InputField}
                    />

                    {initialValues === initialEmpty && (
                      <Field
                        rowLayout={true}
                        label="Data chiusura prossimo esercizio"
                        name="data_bilancio_chiusura"
                        component={DateField}
                      />
                    )}
                    {initialValues !== initialEmpty && (
                      <Field
                        rowLayout={true}
                        label="Data chiusura esercizio"
                        name="data_bilancio_chiusura"
                        component={DateField}
                        dateFormat="dd/MM"
                        disabled
                      />
                    )}
                  </div>
                  <div className="col-4">
                    <div style={{ width: 200, margin: "0 auto" }}>
                      <Field
                        label="Logo aziendale"
                        component={ImageField}
                        name="logo"
                        storeFileName="logo_file"
                        style={{ width: 200, height: 200 }}
                      />
                    </div>
                  </div>
                </div>
              </ModalBody>

              <ModalFooter className="justify-content-between">
                <button
                  type="button"
                  className="btn btn-light"
                  onClick={toggleModal}
                >
                  Annulla
                </button>
                <button
                  className="btn btn-primary"
                  type="submit"
                  disabled={isSubmitting}
                >
                  Salva
                </button>
              </ModalFooter>
            </form>
          </>
        )
      }}
    </Formik>
  )
}
