import React, { useCallback, useContext } from "react"
import { useRj, deps } from "react-rocketjump";
import { BilancioDetailState, BilanciListState } from "../../localstate/bilanci";
import { Modal, ModalHeader, ModalBody, ModalFooter, Button } from "reactstrap";
import { AziendaContext } from "../../providers";
import { useReactDataTable } from "react-use-datatable";
import { format } from "date-fns"
import { FaCopy } from "react-icons/fa"
import Paginator from "../../components/Paginator";

const CopyFromBilancioModal = ({ bilancio, onComplete, toggle }) => {
  const [{ pending }, { run }] = useRj(BilancioDetailState)
  const { currentAzienda } = useContext(AziendaContext);

  const [
    { bilanci, pagination },
    { page, },
    { goToPage }
  ] = useReactDataTable(
    BilanciListState,
    {
      search: {
        field: "search",
        value: undefined
      }
    },
    [deps.maybeGet(currentAzienda, "id")]
  );

  const fetchAndComplete = useCallback(bilancioId => {
    run
      .onSuccess(bilancio => onComplete(bilancio.voci.map(voce => ({ ...voce, bilancio: bilancio.id }))))
      .run(bilancioId)
  }, [onComplete, run])

  return (
    <Modal isOpen={true} toggle={toggle} size="lg">
      <ModalHeader toggle={toggle} className="bg-primary text-white">Copia da bilancio esistente</ModalHeader>
      <ModalBody className="p-0">
        <div className="p-2">
          <p className="text-center">Copia da un bilancio esistente o da una proiezione a fine esercizio</p>
          {bilanci && !pending && (
            <>
              <table className="table table-striped">
                <thead>
                  <th>Data</th>
                  <th className="text-center">Bilancio</th>
                  <th className="text-center">Proiezione</th>
                </thead>
                <tbody>
                  {bilanci.filter(b => b.id !== bilancio.id && b.id !== bilancio.bilancio_base).map(bilancio => (
                    <tr key={bilancio.id}>
                      <td>{format(new Date(bilancio.data_bilancio), "dd/MM/yyyy")}</td>
                      <td className="text-center">
                        <span
                          className="pointer"
                          onClick={() => fetchAndComplete(bilancio.id)}
                        >
                          <FaCopy />
                        </span>
                      </td>
                      <td className="text-center">
                        {!bilancio.bilancio_chiusura && (
                          <span
                            className="pointer"
                            onClick={() => fetchAndComplete(bilancio.bilancio_proiezione)}
                          >
                            <FaCopy />
                          </span>
                        )}
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </>
          )}
          {pending && (
            <p>Copia in corso, attendere...</p>
          )}
        </div>
        {!!bilanci && !pending && (
          <div className="bg-light border-top" style={{ position: "sticky", bottom: 0 }}>
            <Paginator
              page={page}
              pagination={pagination}
              goToPage={goToPage}
            ></Paginator>
          </div>
        )}
      </ModalBody>
      <ModalFooter>
        <div className="w-100 h-100 d-flex flex-row justify-content-between">
          <Button disabled={!bilanci || pending} color="light" type="button" onClick={() => {
            toggle()
          }}
          >Annulla</Button>
        </div>
      </ModalFooter>
    </Modal>
  )
}

export default CopyFromBilancioModal