import { useEffect } from "react"
import { useAuthActions } from "use-eazy-auth"

export function GiuffreAuthWatch() {
  const { login, logout } = useAuthActions()

  useEffect(() => {
    const handler = function(event) {
      console.log("HANDLE EVENT", event)
      if (event?.data?.task === "gethfticket") {
        var token = event.data.params.ticket
        if (token) {
          console.log("LOGIN")
          var returnedToken = token
          login({ username: "__GiuffreRemote", password: returnedToken })
          console.log(returnedToken)
        } else {
          console.log("LOGOUT")
          logout()
        }
      }
    }
    window.addEventListener("message", handler)
    return () => window.removeEventListener("message", handler)
  }, [login, logout])

  useEffect(() => {
    if (window.gflToken) {
      login({ username: "__GiuffreRemote", password: window.gflToken })
    }
  }, [login])

  return null
}
