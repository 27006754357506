import { ajax } from "rxjs/ajax";
import { map } from "rxjs/operators";
import qs from "qs";

const makeListApi = url => {
  return function(t, query = {}) {
    const queryParams = qs.stringify(query);
    return ajax({
      url: `${url}?${queryParams}`,
      headers: {
        Authorization: `Token ${t}`
      }
    }).pipe(map(r => r.response));
  };
};

const makeDetailApi = url => {
  return function(t, id, query = {}) {
    const queryParams = qs.stringify(query);
    return ajax({
      url: `${url}${id}?${queryParams}`,
      headers: {
        Authorization: `Token ${t}`
      }
    }).pipe(map(r => r.response));
  };
};

const makeCreateApi = url => {
  return function(t, payload = {}) {
    return ajax({
      url: `${url}`,
      method: "post",
      headers: {
        Authorization: `Token ${t}`
      },
      body: payload
    }).pipe(map(r => r.response));
  };
};

const makeUpdateApi = (url, method='put') => {
  return function(t, id, payload = {}) {
    return ajax({
      url: `${url}${id}/`,
      method,
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Token ${t}`
      },
      body: payload
    }).pipe(map(r => r.response));
  };
};

const makeDeleteApi = url => {
  return function(t, id) {
    return ajax({
      url: `${url}${id}/`,
      method: "DELETE",
      headers: {
        Authorization: `Token ${t}`
      },
    }).pipe(map(() => id));
  }
}

const makeMultiPartCreateApi = (url, method='POST') => {
  return function(t, payload = {}) {
    const f = new FormData()
    let k
    for(k in payload) {
      if (payload[k] !== undefined) {
        f.append(k, payload[k])
      }
    }
    return ajax({
      url,
      method,
      headers: {
        Authorization: `Token ${t}`
      },
      body: f
    }).pipe(map(r => r.response))
  };
};

const makeMultiPartUpdateApi = (url, method='PUT') => {
  return function(t, id, payload = {}) {
    const f = new FormData()
    let k
    for(k in payload) {
      if (payload[k] !== undefined) {
        f.append(k, payload[k])
      }
    }
    return ajax({
      url: `${url}${id}/`,
      method,
      headers: {
        Authorization: `Token ${t}`
      },
      body: f
    }).pipe(map(r => r.response))
  };
};

const makeDetailActionApi = (url, action, method='post') => {
  return function(t, id, payload = {}) {
    return ajax({
      url: `${url}${id}/${action}/`,
      method,
      headers: {
        Authorization: `Token ${t}`,
        'Content-Type': 'application/json',
      },
      body: payload
    }).pipe(map(r => r.response));
  };
};

export const aziendeApi = makeListApi(`/api/aziende/`);
export const aziendaApi = makeDetailApi(`/api/aziende/`);
export const aziendaCreateApi = makeMultiPartCreateApi(`/api/aziende/`);
export const aziendaUpdateApi = makeMultiPartUpdateApi(`/api/aziende/`)
export const aziendaDeleteApi = makeDeleteApi(`/api/aziende/`)

export const commercialistaApi = makeDetailApi(`/api/commercialisti/`);
export const commercialistaUpdateApi = makeMultiPartUpdateApi(`/api/commercialisti/`)

export const bilanciApi = makeListApi(`/api/bilanci/`);
export const bilancioApi = makeDetailApi(`/api/bilanci/`);
export const bilancioCreateApi = makeCreateApi(`/api/bilanci/`);
export const bilancioUpdateApi = makeUpdateApi(`/api/bilanci/`);
export const bilancioDeleteApi = makeDeleteApi(`/api/bilanci/`);
export const bilancioPartialUpdateApi = makeUpdateApi(`/api/bilanci/`, 'PATCH');
export const bilancioCheckApi = makeDetailActionApi(`/api/bilanci/`, `check`);
export const bilancioAutoProjectApi = makeDetailActionApi(`/api/bilanci/`, `proiezione/auto`, 'get');
export const bilancioSchemaApi = t => {
  return ajax({
    url: `/api/bilanci/schema/`,
    method: "get",
    headers: {
      Authorization: `Token ${t}`
    }
  }).pipe(map(r => r.response));
};

export const indicatoriSchemaApi = t => {
  return ajax({
    url: `/api/bilanci/schema_indicatori/`,
    method: "get",
    headers: {
      Authorization: `Token ${t}`
    }
  }).pipe(map(r => r.response));
};

export const configApi = t => {
  return ajax({
    url: `/api/config/`,
    method: "get",
    headers: {
      Authorization: `Token ${t}`
    }
  }).pipe(map(r => r.response));
};

export const xbrlImportApi = (t, bilancio, file) => {
  const f = new FormData()
  f.append('xbrl_file', file)
  return ajax({
    url: `/api/bilanci/${bilancio}/import_xbrl/`,
    method: "POST",
    body: f,
    headers: {
      Authorization: `Token ${t}`
    }
  }).pipe(map(r => r.response))
}

export const getIndicatoriApi = (t, bilancio, gruppo) => {
  return ajax({
    url: `/api/bilanci/${bilancio}/indicatori/${gruppo}/`,
    method: "GET",
    headers: {
      Authorization: `Token ${t}`
    }
  }).pipe(map(r => r.response))
}

export const liveIndicatoriApi = (t, bilancio, gruppo, inputs) => {
  return ajax({
    url: `/api/bilanci/${bilancio}/indicatori/${gruppo}/live/`,
    method: "POST",
    headers: {
      Authorization: `Token ${t}`,
      'Content-Type': 'application/json',
    },
    body: inputs
  }).pipe(map(r => r.response))
}

export const updateIndicatoriApi = (t, bilancio, gruppo) => {
  return ajax({
    url: `/api/bilanci/${bilancio}/indicatori/${gruppo}/?compute=1`,
    method: "GET",
    headers: {
      Authorization: `Token ${t}`
    }
  }).pipe(map(r => r.response))
}

export const getValoriRettificaIndicatoriApi = (t, bilancio, gruppo) => {
  return ajax({
    url: `/api/bilanci/${bilancio}/indicatori/${gruppo}/params/`,
    method: "GET",
    headers: {
      Authorization: `Token ${t}`
    }
  }).pipe(map(r => r.response))
}

export const updateValoriRettificaIndicatoriApi = (t, bilancio, gruppo, nuoviValori) => {
  return ajax({
    url: `/api/bilanci/${bilancio}/indicatori/${gruppo}/params/`,
    method: "POST",
    headers: {
      Authorization: `Token ${t}`,
      "Content-Type": "application/json"
    },
    body: nuoviValori
  }).pipe(map(r => r.response))
}

export const annotazioneIndicatore = (t, bilancio, gruppo) => {
  return ajax({
    url: `/api/bilanci/${bilancio}/indicatori/${gruppo}/notes/`,
    method: "GET",
    headers: {
      Authorization: `Token ${t}`,
      "Content-Type": "application/json",
    }
  }).pipe(map(r => r.response))
}

export const updateAnnotazioneIndicatore = (t, bilancio, gruppo, note) => {
  return ajax({
    url: `/api/bilanci/${bilancio}/indicatori/${gruppo}/notes/`,
    method: "PUT",
    headers: {
      Authorization: `Token ${t}`,
      "Content-Type": "application/json",
    },
    body: {
      bilancio,
      gruppo,
      note
    }
  }).pipe(map(r => r.response))
}

export const reportApi = (t, bilancioId) => {
  return ajax({
    url: `/api/bilanci/${bilancioId}/report`,
    method: 'GET',
    headers: {
      Authorization: `Token ${t}`,
    },
    responseType: 'blob',
  }).pipe(map(r => [r.response, r.xhr.getResponseHeader('content-disposition').split("filename=")[1]]))
}

export const thresholdsApi = (t, settoreAzienda) => {
  return ajax({
    url: `/api/thresholds/${settoreAzienda}/`,
    method: 'GET',
    headers: {
      Authorization: `Token ${t}`,
    },
  }).pipe(map(r => r.response))
}

export const indicatorStaticsApi = t => {
  return ajax({
    url: `/api/indicator-statics/`,
    method: 'GET',
    headers: {
      Authorization: `Token ${t}`
    }
  }).pipe(map(r => r.response))
}