import React, { useState, useContext, useCallback } from "react"
import Header from "../../components/Header"
import Menu from "../../components/Menu"
import Layout from "react-drawers"
import { Link } from "react-router-dom"

import { AziendeListState } from "../../localstate/aziende"
import { useQsDataTable } from "react-use-datatable"
import Paginator from "../../components/Paginator"
import { Modal } from "reactstrap"
import AziendaForm from "./AziendaForm"
import { AziendaContext } from "../../providers"
import { useAuthUser } from "use-eazy-auth"
import AccountUpgradeModal from "./AccountUpgradeModal"
import { format } from "date-fns"
import { evalRiskColor } from "../../utils"

import "./Aziende.scss"
import { Onboarding } from "../../components/Onboarding"

const Aziende = ({ history }) => {
  const [
    { aziende, pagination },
    { page, ...filters },
    { goToPage, addAzienda, run }
  ] = useQsDataTable(AziendeListState, {
    search: {
      field: "search",
      value: undefined
    }
  })
  const { user } = useAuthUser()

  const [adding, setAdding] = useState(false)
  const [showAccountUpgradeModal, setShowAccountUpgradeModal] = useState(false)
  const toggleAdding = () => {
    if (
      user.dati_commercialista.max_aziende === null ||
      user.dati_commercialista.max_aziende > pagination.count
    ) {
      setAdding(!adding)
    } else {
      setShowAccountUpgradeModal(true)
    }
  }
  const { setCurrentAzienda } = useContext(AziendaContext)

  const toggleAccountUpgradeModal = useCallback(() => {
    setShowAccountUpgradeModal(prev => !prev)
  }, [])

  return (
    <>
      <Layout absolute className="classname-applied-to-content pagina-aziende">
        <Layout.Top className="top-classname">
          <Header></Header>
        </Layout.Top>
        <Layout.Left className="left-classname">
          <Menu></Menu>
        </Layout.Left>
        <Layout.Right className="right-classname"></Layout.Right>

        <Layout.Content className="h-100 overflow-auto with-sticky-footer">
          <div className="sticky-header bg-light p-3">
            <div className="d-flex justify-content-between align-items-center">
              <h4 className="m-0">Aziende</h4>
              <div className="">
                <div className="input-group mr-2"></div>
                <button
                  onClick={toggleAdding}
                  className="btn btn-sm btn-primary"
                >
                  Aggiungi azienda
                </button>
              </div>
            </div>
          </div>

          <div className="p-3">
            {aziende ? (
              <div>
                <table className="table table-striped">
                  <thead>
                    <tr>
                      <th
                        style={{ width: 50, paddingLeft: 5, paddingRight: 5 }}
                      >
                        Logo
                      </th>
                      <th>Ragione sociale</th>
                      <th className="text-center">Ultimo bilancio</th>
                      <th className="text-center">
                        Grado di rischio{" "}
                        <span
                          className="fas fa-circle-info"
                          style={{ cursor: "help" }}
                          title="Fa riferimento all'ultimo bilancio caricato"
                        />
                      </th>
                      <th></th>
                    </tr>
                  </thead>
                  <tbody>
                    {aziende.map(azienda => (
                      <tr key={azienda.id}>
                        <td style={{ width: 50, padding: 5 }}>
                          {azienda.logo && (
                            <img
                              src={azienda.logo}
                              alt=""
                              style={{
                                width: 40,
                                height: 40,
                                objectFit: "contain"
                              }}
                            />
                          )}
                        </td>
                        <td>
                          <Link to={`/aziende/${azienda.id}`}>
                            {azienda.ragione_sociale}
                          </Link>
                        </td>
                        <td className="text-center">
                          {azienda.data_ultimo_bilancio &&
                            format(
                              new Date(azienda.data_ultimo_bilancio),
                              "dd/MM/yyyy"
                            )}
                        </td>
                        <td className="text-center">
                          <div
                            className={`box ${
                              azienda.overall_risk !== null &&
                              azienda.overall_risk !== undefined
                                ? evalRiskColor(azienda.overall_risk)
                                : ""
                            }`}
                          >
                            {azienda.overall_risk &&
                              Math.round(azienda.overall_risk * 100) + "%"}
                          </div>
                        </td>
                        <td>
                          <button
                            className="btn btn-sm btn-primary mr-2"
                            onClick={() => {
                              setCurrentAzienda(azienda)
                              history.push("/bilanci")
                            }}
                          >
                            Visualizza bilanci
                          </button>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            ) : null}
          </div>

          <div className="sticky-footer bg-light border-top">
            <Paginator
              page={page}
              pagination={pagination}
              goToPage={goToPage}
            ></Paginator>
          </div>
        </Layout.Content>

        <Modal isOpen={adding} size="lg">
          <AziendaForm
            save={values =>
              addAzienda.asPromise(values).then(() => {
                run({ ...filters, page })
              })
            }
            toggleModal={toggleAdding}
          ></AziendaForm>
        </Modal>

        <AccountUpgradeModal
          isOpen={showAccountUpgradeModal}
          toggle={toggleAccountUpgradeModal}
        />
      </Layout>
      <Onboarding />
    </>
  )
}

export default Aziende
