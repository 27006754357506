import React, { useCallback } from "react"
import { useRunRj, deps } from "react-rocketjump"
import { BilancioDetailState } from "../../localstate/bilanci"
import BilancioEditor from "./BilancioEditor"
import BilancioProjectionFooter from "./BilancioProjectionFooter"

const BilancioEditorCompare = ({ bilancioBaseId, bilancioFineEsercizioId, bilancioTargetId, makeSave, ...props }) => {
  const [{ bilancio: bilancioBase }] = useRunRj(BilancioDetailState, [deps.maybe(bilancioBaseId)]);
  const [{ bilancio: bilancioFineEsercizio }] = useRunRj(BilancioDetailState, [deps.maybe(bilancioFineEsercizioId)]);
  const [{ bilancio }, { updateBilancio }] = useRunRj(BilancioDetailState, [deps.maybe(bilancioTargetId)]);

  const save = useCallback(makeSave(updateBilancio), [makeSave, updateBilancio])

  if ((bilancioBaseId && !bilancioBase) || (bilancioFineEsercizioId && !bilancioFineEsercizio) || (!bilancio)) {
    return null
  }

  return (
    <BilancioEditor
      bilancioBase={bilancioBase}
      bilancioFineEsercizio={bilancioFineEsercizio}
      bilancio={bilancio}
      save={save}
      footerComponent={BilancioProjectionFooter}
      {...props}
    />
  )
   
}

export default BilancioEditorCompare