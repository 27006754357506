import React from "react";
import { useRunRj, deps } from "react-rocketjump";
import {
  AnnotazioneIndicatoriState,
  ValoriRettificaIndicatoriBilancioState,
} from "../../localstate/bilanci";
import ReportHeader from "./Header";
import IndicatorCard from "./IndicatorCard";
import get from "lodash/get";
import keyBy from "lodash/keyBy";
import mapValues from "lodash/mapValues";
import { Table } from "reactstrap";
import { putSpacesEvery000 } from "../../utils";

function formatValue(value) {
  return putSpacesEvery000(parseFloat(value).toFixed(2)) + "€";
}

const TCacPage = (props) => {
  const {
    bilancio,
    schemaIndicatori,
    indicatoriCac: indicatori,
    indicatorsStatics,
    thresholds,
  } = props;
  const [{ data: notes }] = useRunRj(AnnotazioneIndicatoriState, [
    deps.maybeGet(bilancio, "id"),
    "RISANAMENTO",
  ]);
  const [
    { list: valoriRettifica },
  ] = useRunRj(ValoriRettificaIndicatoriBilancioState, [
    deps.maybeGet(bilancio, "id"),
    "RISANAMENTO",
  ]);

  if (!indicatori) {
    return null;
  }

  const Indicators = schemaIndicatori["RISANAMENTO"].indicators;

  const valoreParzialiIndicatore = mapValues(
    keyBy(indicatori, "codice"),
    "valore"
  );

  const tcac = indicatori.find((i) => i.codice === "TCAC");

  const params = mapValues(keyBy(valoriRettifica, "nome"), "valore");
  console.log(params, valoriRettifica);

  return (
    <div className="page indicator-page ida-page">
      <ReportHeader {...props} />
      <h1>TEST COMPOSIZIONE NEGOZIATA CRISI</h1>
      <h2>
        Test per la verifica della ragionevole perseguibilità del
        risanamento
      </h2>
      <div className="grid-1x3 mt-3">
        <IndicatorCard
          indicatorsStatics={indicatorsStatics}
          thresholds={thresholds}
          indicatorName="TCAC"
          indicatorDescription={Indicators["TCAC"]}
          indicatorValue={tcac}
        />
        <p style={{ gridColumn: "2 / span 2" }}>
          {/* Put some references here */}
        </p>
      </div>
      <Table size="sm" className="mt-4">
        <thead>
          <tr className="bg-dark text-white">
            <td>Descrizione</td>
            <td>Valore</td>
          </tr>
        </thead>
        <tbody>
          {["DS", "DR", "LCBe", "FsL", "Inv", "Cess", "NF", "MoNn"].map(
            (inputName) => (
              <tr key={inputName}>
                <td className="align-middle">
                  {Indicators["TCAC"].inputs[inputName].name}
                </td>
                <td
                  className="pl-4 text-right"
                  style={{ whiteSpace: "nowrap" }}
                >
                  {formatValue(params?.[inputName])}
                </td>
              </tr>
            )
          )}
          <tr style={{ backgroundColor: "#f9ff87" }}>
            <td className="align-middle font-weight-bold">
              {"Totale Debito da ristrutturare"}
            </td>
            <td className="pl-4 text-right" style={{ whiteSpace: "nowrap" }}>
              {formatValue(valoreParzialiIndicatore.TDr)}
            </td>
          </tr>
          <tr>
            <td className="align-middle">
              {
                "Stima del Margine Operativo Lordo prospettico normalizzato annuo, prima delle componenti non ricorrenti, a regime"
              }
            </td>
            <td className="pl-4 text-right" style={{ whiteSpace: "nowrap" }}>
              {formatValue(valoreParzialiIndicatore.MOL)}
            </td>
          </tr>
          {["InvM", "ImpR"].map((inputName) => (
            <tr key={inputName}>
              <td className="align-middle">
                {Indicators["TCAC"].inputs[inputName].name}
              </td>
              <td className="pl-4 text-right" style={{ whiteSpace: "nowrap" }}>
                {formatValue(params?.[inputName])}
              </td>
            </tr>
          ))}
          <tr style={{ backgroundColor: "#f9ff87" }}>
            <td className="align-middle font-weight-bold">
              {"Totale Flussi annui di risanamento"}
            </td>
            <td className="pl-4 text-right" style={{ whiteSpace: "nowrap" }}>
              {formatValue(valoreParzialiIndicatore.TFr)}
            </td>
          </tr>
        </tbody>
      </Table>
      {get(notes, "note", null) && (
        <>
          <h2>Annotazioni</h2>
          <p className="note">{get(notes, "note", null)}</p>
        </>
      )}
    </div>
  );
};

export default TCacPage;
