import React from "react"
import { putSpacesEvery000 } from "../utils"
import find from "lodash/find"

const IndicatorValue = ({ indicatorDescription, rawValue }) => {
  return (
    <>
      {indicatorDescription.unit === "€" && (
        rawValue
          ? putSpacesEvery000(parseFloat(rawValue).toFixed(2)) + " €"
          : "- €"
      )}
      {indicatorDescription.unit === "%" && (
        rawValue
          ? (rawValue * 100).toFixed(2) + " %"
          : "- %"
      )}
      {indicatorDescription.unit === null && (
        rawValue
          ? parseFloat(rawValue).toFixed(2)
          : "-"
      )}
      {indicatorDescription.unit === "enum" && (
        rawValue
          ? find(indicatorDescription.labels, (label, value) => value === parseInt(rawValue))
          : "-"
      )}
    </>
  )
}

export default IndicatorValue