import React from "react"
import { Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap"

const XbrlWarnModal = ({ isOpen, toggle }) => {
  return (
    <Modal isOpen={isOpen} toggle={toggle}>
      <ModalHeader toggle={toggle} className="bg-primary text-white">
        Attenzione
      </ModalHeader>
      <ModalBody>
        <p>
          Il file XBRL caricato usa una tassonomia parzialmente supportata, pertanto l'importazione 
          potrebbe essere riuscita solo parzialmente. Si invita a verificare ed eventualmente integrare
          manualmente i dati caricati.
        </p>
      </ModalBody>
      <ModalFooter>
        <div className="w-100 h-100 d-flex flex-row justify-content-end align-items-center">
          <button
            className="btn btn-sm btn-success"
            onClick={toggle}
          >OK</button>
        </div>
      </ModalFooter>
    </Modal>
  )
}

export default XbrlWarnModal