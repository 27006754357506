import React from "react"
import "./Header.scss"
import LogoGiuffre from "./assets/logo_giuffre.png"
import { format } from "date-fns"

const ReportHeader = ({ azienda, bilancio, config }) => {
  return (
    <div className="report-header">
      <div className="logo-azienda">
        <img src={azienda.logo} alt="" />
      </div>
      <div className="logo-giuffre">
        <img src={LogoGiuffre} alt="" />
      </div>
      <div className="info-bilancio">
        <table>
          <tbody>
            <tr>
              <td>
                <h1>Azienda</h1>
              </td>
              <td>{azienda.ragione_sociale}</td>
            </tr>
            <tr>
              <td>
                <h2>Data Bilancio</h2>
              </td>
              <td>{format(new Date(bilancio.data_bilancio), "dd/MM/yyyy")}</td>
            </tr>
            <tr>
              <td>
                <h2>Settore</h2>
              </td>
              <td>{config.settori.find(settore => settore[0] === azienda.settore)[1]}</td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  )
}

export default ReportHeader