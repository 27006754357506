import { rj } from "react-rocketjump";
import rjList, {
  nextPreviousPaginationAdapter
} from "react-rocketjump/plugins/list";
import rjDebounce from "react-rocketjump/plugins/debounce"
import rjPlainList from "react-rocketjump/plugins/plainList"
import keyBy from "lodash/keyBy"

import {
  bilanciApi,
  bilancioApi,
  bilancioCreateApi,
  bilancioUpdateApi,
  bilancioCheckApi,
  bilancioSchemaApi,
  xbrlImportApi,
  getIndicatoriApi,
  updateIndicatoriApi,
  indicatoriSchemaApi,
  getValoriRettificaIndicatoriApi,
  updateValoriRettificaIndicatoriApi,
  liveIndicatoriApi,
  bilancioAutoProjectApi,
  bilancioPartialUpdateApi,
  annotazioneIndicatore,
  updateAnnotazioneIndicatore,
  bilancioDeleteApi
} from "../api";

const bilanciByAziendaApi = (t, azienda, params) =>
  bilanciApi(t, { azienda: azienda, ...params });

export const BilanciListState = rj(
  rjList({
    pageSize: 50,
    pagination: nextPreviousPaginationAdapter
  }),
  {
    name: "bilanci",

    effectCaller: rj.configured(),
    effect: t => (azienda, params) => bilanciByAziendaApi(t, azienda, params),

    mutations: {
      addBilancio: rj.mutation.single({
        effectCaller: rj.configured(),
        effect: t => payload => bilancioCreateApi(t, payload),
        updater: (state, bilancio) => {
          return {
            ...state,
            data: {
              ...state.data,
              list: state.data.list.concat(bilancio)
            }
          };
        }
      }),
      update: rj.mutation.single({
        effect: t => (id, payload) => bilancioPartialUpdateApi(t, id, payload),
        updater: "updateItem"
      }),
      deleteBilancio: rj.mutation.single({
        effect: t => id => bilancioDeleteApi(t, id),
        updater: (state, result) => state
      })
    },

    computed: {
      bilanci: "getList",
      bilanciLoading: "isLoading",
      bilancioSaving: "@mutation.addBilancio.pending"
    }
  }
);

export const BilancioDetailState = rj({
  name: "bilancio",
  effectCaller: rj.configured(),
  effect: t => (id, query) => bilancioApi(t, id, query),

  mutations: {
    updateBilancio: rj.mutation.single({
      effectCaller: rj.configured(),
      effect: t => (id, payload) => bilancioUpdateApi(t, id, payload),
      updater: (state, bilancio) => {
        return {
          ...state,
          data: bilancio
        };
      }
    }),
    checkBilancio: rj.mutation.single({
      effectCaller: rj.configured(),
      effect: t => (id, payload) => bilancioCheckApi(t, id, payload),
      updater: (state, bilancio) => {
        return {
          ...state,
          data: bilancio
        };
      },
      takeEffect: "latest"
    }),

  },

  computed: {
    bilancio: "getData",
    bilancioSaving: "@mutation.updateBilancio.pending",
    bilancioChecking: "@mutation.checkBilancio.pending"
  }
});

export const BilancioSchemaState = rj({
  name: "config",
  effectCaller: rj.configured(),
  effect: t => () => bilancioSchemaApi(t),
  computed: {
    schema: "getData"
  }
});


export const CheckBilancioDetailState = rj(rjDebounce(200), {
  name: "checkBilancio",
  effectCaller: rj.configured(),
  effect: t => (id, payload) => bilancioCheckApi(t, id, payload),
  takeEffect: "latest",
});

export const ParseXbrlState = rj({
  name: "readXbrl",
  effectCaller: rj.configured(),
  effect: t => (...args) => xbrlImportApi(t, ...args)
})


export const IndicatoriBilancioState = rj(
  rjPlainList(),
  {
    name: "getIndicatori",
    effectCaller: rj.configured(),
    effect: t => (bilancioId, gruppo) => getIndicatoriApi(t, bilancioId, gruppo),
    mutations: {
      aggiornaIndicatori: rj.mutation.single({
        effect: t => (bilancioId, gruppo) => updateIndicatoriApi(t, bilancioId, gruppo),
        updater: (state, result) => {
          const index = keyBy(state.data, item => `${item.gruppo}:${item.codice}`)
          result.forEach(item => {
            index[`${item.gruppo}:${item.codice}`] = item
          })
          return {
            ...state,
            data: Object.values(index)
          }
        }
      })
    }
  }
)

export const ValoriRettificaIndicatoriBilancioState = rj(
  rjPlainList(),
  {
    name: "getValoriRettifica",
    effectCaller: rj.configured(),
    effect: t => (bilancioId, gruppo) =>
      getValoriRettificaIndicatoriApi(t, bilancioId, gruppo),
    mutations: {
      update: rj.mutation.single({
        effect: t => (bilancioId, gruppo, nuoviValori) =>
          updateValoriRettificaIndicatoriApi(t, bilancioId, gruppo, nuoviValori),
        updater: (state, result) => {
          const index = keyBy(state.data, item => `${item.gruppo}:${item.nome}`)
          result.forEach(item => {
            index[`${item.gruppo}:${item.nome}`] = item
          })
          return {
            ...state,
            data: Object.values(index)
          }
        }
      })
    }
  }
)

export const IndicatoriLiveState = rj(
  rjDebounce(200),
  rjPlainList(),
  {
    name: "indicatoriLive",
    effectCaller: rj.configured(),
    effect: t => (bilancioId, gruppo, inputs) => liveIndicatoriApi(t, bilancioId, gruppo, inputs),
  }
)

export const SchemaIndicatoriState = rj({
  name: "schemaIndicatori",
  effectCaller: rj.configured(),
  effect: t => () => indicatoriSchemaApi(t)
})

export const BilancioAutoProjectState = rj({
  name: "autoProiezioneBilancio",
  effectCaller: rj.configured(),
  effect: t => bilancioId => bilancioAutoProjectApi(t, bilancioId)
})

export const AnnotazioneIndicatoriState = rj({
  name: "annotazioneIndicatori",
  effectCaller: rj.configured(),
  effect: t => (bilancioId, gruppo) => annotazioneIndicatore(t, bilancioId, gruppo),
  mutations: {
    update: rj.mutation.single({
      effect: t => (bilancio, gruppo, note) => updateAnnotazioneIndicatore(t, bilancio, gruppo, note),
      updater: "updateData"
    })
  }
})