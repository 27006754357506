const __store = {}

const InMemoryStorageBackend = {
  getItem: key => __store[key],
  setItem: (key, value) => __store[key] = value,
  removeItem: key => delete __store[key]
}

let hasLocalStorage = true
const check = "__check_ls_support"
try {
  localStorage.setItem(check, check);
  localStorage.removeItem(check);
  hasLocalStorage = true;
} catch (e) {
  hasLocalStorage = false;
}

const ProxyStorage = {
  getItem: (...args) => {
    if (hasLocalStorage) {
      return localStorage.getItem(...args)
    } else {
      return InMemoryStorageBackend.getItem(...args)
    }
  },
  setItem: (...args) => {
    if (hasLocalStorage) {
      localStorage.setItem(...args)
    } else {
      InMemoryStorageBackend.setItem(...args)
    }
  },
  removeItem: (...args) => {
    if (hasLocalStorage) {
      localStorage.removeItem(...args)
    } else {
      InMemoryStorageBackend.removeItem(...args)
    }
  }
}

export default ProxyStorage