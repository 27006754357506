import React from "react"
import { ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import { Field, Formik } from "formik";
import { InputField } from "../../components/Fields";
import ImageField from "../../components/Fields/ImageField";



const ProfileForm = ({
  initialValues,
  title = "Modifica profilo",
  toggleModal,
  save
}) => {
  return (
    <Formik
      onSubmit={(values, actions) => {
        const { logo_studio, logo_file, ...storeValues } = values
        if (logo_file !== undefined && logo_file !== null && logo_file instanceof File) {
          storeValues["logo_studio"] = logo_file
        }
        return save(storeValues)
          .then(() => {
            actions.setSubmitting(false);
            toggleModal();
          })
          .catch(err => {
            actions.setErrors(err.response);
            actions.setSubmitting(false);
          });
      }}
      initialValues={initialValues}
    >
      {({
        handleSubmit,
        isSubmitting
      }) => {
        return (
          <>
            <form onSubmit={handleSubmit}>
              <ModalHeader toggle={toggleModal} className="bg-dark text-white">
                {title}
              </ModalHeader>
              <ModalBody>
                <div className="row">
                  <div className="col-8">
                    <Field
                      rowLayout={false}
                      type="text"
                      label="Nome studio"
                      name="nome_studio"
                      component={InputField}
                    />
                  </div>
                  <div className="col-4">
                    <div style={{ width: 200, margin: "0 auto" }}>
                      <Field
                        label="Logo studio"
                        component={ImageField}
                        name="logo_studio"
                        storeFileName="logo_file"
                        style={{ width: 200, height: 200 }}
                      />
                    </div>
                  </div>
                </div>

              </ModalBody>

              <ModalFooter className="justify-content-between">
                <button
                  type="button"
                  className="btn btn-light"
                  onClick={toggleModal}
                >
                  Annulla
                </button>
                <button
                  className="btn btn-primary"
                  type="submit"
                  disabled={isSubmitting}
                >
                  Salva
                </button>
              </ModalFooter>
            </form>
          </>
        );
      }}
    </Formik>
  );
}

export default ProfileForm