import React, { useCallback, useRef } from "react";
import "./ImageField.scss";
import get from "lodash/get";

const ImageField = ({
  form,
  field,
  label,
  storeFileName,
  labelClassName,
  className,
  ...props
}) => {
  const inputRef = useRef(null);

  const handleChange = useCallback(
    (e) => {
      const selectedFile = e.target.files[0];
      if (selectedFile) {
        const url = URL.createObjectURL(selectedFile);
        if (get(form.values, storeFileName) !== undefined) {
          URL.revokeObjectURL(field.value);
        }
        form.setFieldValue(field.name, url);
        form.setFieldValue(storeFileName, selectedFile);
      }
      e.target.value = "";
    },
    [field.name, field.value, form, storeFileName]
  );

  return (
    <>
      <label className={labelClassName}>{label}</label>
      <div
        {...props}
        className={`${className} ImageField`}
        onClick={() => inputRef.current && inputRef.current.click()}
      >
        <p>Click per inserire</p>
        <input ref={inputRef} type="file" onChange={handleChange} />
        {field.value && (
          <>
            <div className="img">
              <img src={field.value} alt="" />
            </div>
            <div className="overlay">{"Click per modificare"}</div>
          </>
        )}
      </div>
      {field.value && (
        <div
          className="text-center"
          onClick={() => {
            if (get(form.values, storeFileName) !== undefined) {
              URL.revokeObjectURL(field.value);
            }
            form.setFieldValue(field.name, null);
            form.setFieldValue(storeFileName, null);
          }}
        >
          <small className="pointer">Rimuovi immagine</small>
        </div>
      )}
    </>
  );
};

export default ImageField;
