import React from "react"
import { useRunRj, deps } from "react-rocketjump"
import { AnnotazioneIndicatoriState } from "../../localstate/bilanci"
import ReportHeader from "./Header"
import IndicatorCard from "./IndicatorCard"
import get from "lodash/get"

const ISPIPage = props => {
  const { bilancio, schemaIndicatori, indicatoriIspi: indicatori, indicatorsStatics, thresholds } = props
  const [{ data: notes }] = useRunRj(AnnotazioneIndicatoriState, [deps.maybeGet(bilancio, "id"), "ISPI"])

  if (!indicatori) {
    return null
  }

  const ISPIIndicators = schemaIndicatori["ISPI"].indicators

  const zScore = indicatori.find(i => i.codice === "ZScore")
  const oScore = indicatori.find(i => i.codice === "OScore")
  const zmijewski = indicatori.find(i => i.codice === "Zmijewski")

  return (
    <div className="page indicator-page ida-page">
      <ReportHeader {...props} />
      <h1>ALTRI INDICI</h1>
      <h2>Indici Statistici di Previsione Insolvenze</h2>
      <h2>Z"-Score Altman per PMI Italia</h2>
      <div className="grid-1x3 mt-3">
        <IndicatorCard
          indicatorsStatics={indicatorsStatics}
          thresholds={thresholds}
          indicatorName="ZScore"
          indicatorDescription={ISPIIndicators["ZScore"]}
          indicatorValue={zScore}
        />
        <p style={{ gridColumn: "2 / span 2" }}>
          Lo Z-score, non ha una natura probabilistica, ma descrittivo comparativa,
          si limita ad individuare un andamento comune degli indicatori contabili negli
          anni precedenti il fallimento, distinguendo tra i valori degli indici di bilancio,
          che meglio caratterizzano le imprese sane da quelle insolventi.
          In tal modo, si riesce a formulare ipotesi per il futuro.
        </p>
      </div>

      <h2>O-Score di Ohlson</h2>
      <div className="grid-1x3 mt-3">
        <IndicatorCard
          indicatorsStatics={indicatorsStatics}
          thresholds={thresholds}
          indicatorName="OScore"
          indicatorDescription={ISPIIndicators["OScore"]}
          indicatorValue={oScore}
        />
        <p style={{ gridColumn: "2 / span 2" }}>
          Partendo dall’ipotesi che il modello di Altman (Z-score), basato sull’analisi
          discriminatoria, è troppo restrittivo in quanto richiede, come condizione preliminare,
          che le variabili (X1 - X4) siano distribuite normalmente, Ohlson propone un modello
          alternativo che misura la probabilità dell’insolvenza che si basa su ben 9 variabili (X1 - X9).
          Se l’indicatore è inferiore al punto di cut-off: 0,038 l’impresa è sana,
          se l’indicatore è sopra il valore soglia di 0,038 l’impresa tenderà ad essere insolvente.
        </p>
      </div>

      <h2>Zmijewski Score</h2>
      <div className="grid-1x3 mt-3">
        <IndicatorCard
          indicatorsStatics={indicatorsStatics}
          thresholds={thresholds}
          indicatorName="Zmijewski"
          indicatorDescription={ISPIIndicators["Zmijewski"]}
          indicatorValue={zmijewski}
        />
        <p style={{ gridColumn: "2 / span 2" }}>
          Il modello segue un approccio più parsimonioso, usando solo tre variabili,
          rendendolo più semplice, ma anche più povero dal punto di vista informativo.
          Se il risultato è maggiore di 0,5 l’impresa è a rischio default, se inferiore a 0,5
          si considera sana.
        </p>
      </div>
      {get(notes, "note", null) && (
        <>
          <h2>Annotazioni</h2>
          <p className="note">{get(notes, "note", null)}</p>
        </>
      )}
    </div>
  )

}

export default ISPIPage