import React from "react"
import { Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap"

const AccountUpgradeModal = ({ isOpen, toggle }) => {
  return (
    <Modal isOpen={isOpen} size="md" toggle={toggle}>
      <ModalHeader className="bg-dark text-white" toggle={toggle}>
        Espansione richiesta
      </ModalHeader>
      <ModalBody>
        <p style={{ textAlign: "justify" }}>
          È stato raggiunto il numero massimo di aziende concesso per questo account. 
          Per inserire una o più ulteriori aziende è necessario acquistare presso 
          l'editore un pacchetto di espansione.
        </p>
      </ModalBody>
      <ModalFooter>
        <div className="d-flex flex-row align-items-center justify-content-end">
          <button className="btn btn-sm btn-primary" onClick={toggle}>
            OK
          </button>
        </div>
      </ModalFooter>
    </Modal>
  )
}

export default AccountUpgradeModal