import React, { useState, useCallback } from 'react'
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap'

const WithConfirmationComponent = ({ 
  wrappedComponent, 
  onClick, 
  caption = 'Sei sicuro di voler eseguire questa operazione?',
  title = 'Richiesta conferma',
  confirmText = 'Conferma',
  cancelText = 'Annulla',
  confirmColor = 'danger',
  cancelColor = 'light',
  modalClassName = '',
  headerClassName = '',
  bodyClassName = '',
  buttonClassName = '',
  confirmButtonClassName = '',
  cancelButtonClassName = '',
  ...props
}) => {

  const [isOpen, setOpen] = useState(false)
  
  const toggle = useCallback(() => {
    setOpen(prev => !prev)
  }, [])

  const confirm = useCallback(() => {
    toggle()
    onClick && onClick()
  }, [onClick, toggle])

  const WrappedComponent = wrappedComponent

  return (
    <>
      <WrappedComponent {...props} onClick={toggle} data-toggle="modal"/>
      <Modal isOpen={isOpen} toggle={toggle} className={modalClassName}>
        <ModalHeader className={headerClassName} toggle={toggle}>{title}</ModalHeader>
        <ModalBody className={bodyClassName}>
          {caption}
        </ModalBody>
        <ModalFooter>
          <div className="d-flex flex-row justify-content-between w-100">
            <Button color={cancelColor} className={`${buttonClassName} ${confirmButtonClassName}`} onClick={toggle}>{cancelText}</Button>
            <Button color={confirmColor} className={`${buttonClassName} ${cancelButtonClassName}`} onClick={confirm}>{confirmText}</Button>
          </div>
        </ModalFooter>
      </Modal>
    </>
  )

}

const WithConfirmation = WrappedComponent => props => (
  <WithConfirmationComponent wrappedComponent={WrappedComponent} {...props} />
)

export default WithConfirmation