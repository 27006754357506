import React from "react"
import "./Notes.scss"
import ReportHeader from "./Header"

const Notes = props => {
  return (
    <div className="page notes-page">
      <ReportHeader {...props}/>
      <h1>Note Metodologiche</h1>
      <ol>
        <li>
          La scelta esplicita, da parte del Consiglio Nazionale Commercialisti, 
          di limitare il rischio di determinare “falsi positivi” a scapito di un 
          maggior numero di “falsi negativi” (par. 2.5 documento “Indici di Allerta”) 
          impone da parte dell’analista la particolare attenzione a non valutare <u>il mancato 
          superamento del test dei 5 indici di allerta, come motivo sufficiente per 
          poter escludere la presenza di <i>fondati indizi di crisi.</i></u>
        </li>
        <li>
          Per le imprese costituite da <b>meno di due anni</b> l’unico indice che rileva è il 
          “Patrimonio netto negativo”, il sistema calcola comunque anche tutti gli altri indici.
        </li>
        <li>
          Per le imprese in <b>Liquidazione</b> inattive si deve adottare il rapporto 
          “valore di realizzo” / debiti totali, indice che qui non viene calcolato.
        </li>
        <li>
          Nel caso di caricamento di un bilancio infra - annuale, tutti gli indicatori 
          riportati nella sezione “Altri Indicatori” sono calcolati a partire dal Bilancio Prospettico 
          a fine esercizio.
        </li>
      </ol>
    </div>
  )
}

export default Notes