import React from "react"
import ReportHeader from "./Header"
import get from "lodash/get"
import ThresholdsTable from "../../components/ThresholdsTable"
import "./ThresholdsPage.scss"

const getFullName = (code, indicatorsStatics) => {
  return get(get(indicatorsStatics, code, {}), "descrizione_breve", "")
}

const IndicatorBlock = ({ schemaIndicatori, thresholds, blockCode, indicatorsStatics, styles }) => (
  <div className={`grid-${Math.ceil(Object.keys(schemaIndicatori[blockCode].indicators).length / 3)}x3`}>
    {Object.keys(schemaIndicatori[blockCode].indicators).map(code => (
      <div className="table-with-caption" style={get(styles, code, {})}>
        <p>{getFullName(code, indicatorsStatics) || schemaIndicatori[blockCode].indicators[code].description}</p>
        <ThresholdsTable indicatorCode={code} thresholds={thresholds} />
      </div>
    ))}
  </div>
)

const ThresholdsPage = props => {
  const { azienda, schemaIndicatori, config, bilancio, thresholds, indicatorsStatics } = props

  return (
    <>
      <div className="page ThresholdsPage">
        <ReportHeader {...props} />
        <h1>VALORI SOGLIA PER L'ATTRIBUZIONE DEI PUNTEGGI</h1>
        <p>Settore: {config.settori.find(settore => settore[0] === azienda.settore)[1]}</p>
        <div className="atomic">
          {bilancio.bilancio_chiusura && (
            <h2>Indici di allerta consuntivi</h2>
          )}
          {!bilancio.bilancio_chiusura && (
            <h2>Indici di allerta consuntivi e previsionali</h2>
          )}
          <IndicatorBlock
            indicatorsStatics={indicatorsStatics}
            thresholds={thresholds}
            schemaIndicatori={schemaIndicatori}
            blockCode="IdA"
          />
        </div>

      </div>
      <div className="page ThresholdsPage">
        <ReportHeader {...props} />
        <h1>VALORI SOGLIA PER L'ATTRIBUZIONE DEI PUNTEGGI</h1>
        <p>Settore: {config.settori.find(settore => settore[0] === azienda.settore)[1]}</p>
        <div className="atomic">
          <h2>Indici Sintetici di Continuità Aziendale</h2>
          <IndicatorBlock
            indicatorsStatics={indicatorsStatics}
            thresholds={thresholds}
            schemaIndicatori={schemaIndicatori}
            blockCode="ICAS"
            styles={{
              ICA8: {
                gridColumn: "2 / span 2"
              }
            }}
          />
        </div>
      </div>

      <div className="page ThresholdsPage">
        <ReportHeader {...props} />
        <h1>VALORI SOGLIA PER L'ATTRIBUZIONE DEI PUNTEGGI</h1>
        <p>Settore: {config.settori.find(settore => settore[0] === azienda.settore)[1]}</p>

        <div className="atomic">
          <h2>Free Cash Flow Operativo e Tasso di Sviluppo Sostenibile</h2>
          <IndicatorBlock
            indicatorsStatics={indicatorsStatics}
            thresholds={thresholds}
            schemaIndicatori={schemaIndicatori}
            blockCode="FCFO"
            styles={{ TSS: { gridColumn: "2 / span 2" } }}
          />
        </div>

        <div className="atomic">
          <h2>Leve Aziendali</h2>
          <IndicatorBlock
            indicatorsStatics={indicatorsStatics}
            thresholds={thresholds}
            schemaIndicatori={schemaIndicatori}
            blockCode="LEVE"
          />
        </div>

        <div className="atomic">
          <h2>Break Even Point</h2>
          <IndicatorBlock
            indicatorsStatics={indicatorsStatics}
            thresholds={thresholds}
            schemaIndicatori={schemaIndicatori}
            blockCode="BEP"
          />
        </div>
      </div>

      <div className="page ThresholdsPage">
        <ReportHeader {...props} />
        <h1>VALORI SOGLIA PER L'ATTRIBUZIONE DEI PUNTEGGI</h1>
        <p>Settore: {config.settori.find(settore => settore[0] === azienda.settore)[1]}</p>
        <div className="atomic">
          <h2>Indici Statistici di Previsione Insolvenze</h2>
          <IndicatorBlock
            indicatorsStatics={indicatorsStatics}
            thresholds={thresholds}
            schemaIndicatori={schemaIndicatori}
            blockCode="ISPI"
          />
        </div>
      </div>

      <div className="page ThresholdsPage">
        <ReportHeader {...props} />
        <h1>VALORI SOGLIA PER L'ATTRIBUZIONE DEI PUNTEGGI</h1>
        <p>Settore: {config.settori.find(settore => settore[0] === azienda.settore)[1]}</p>
        <div className="atomic">
          <h2>Test per la verifica della ragionevole perseguibilità del risanamento</h2>
          <IndicatorBlock
            indicatorsStatics={indicatorsStatics}
            thresholds={thresholds}
            schemaIndicatori={schemaIndicatori}
            blockCode="RISANAMENTO"
          />
        </div>
      </div>
    </>
  )
}

export default ThresholdsPage