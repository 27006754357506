import React from "react"
import IconAzienda from "../assets/icons/azienda.svg"
import IconBilanci from "../assets/icons/bilanci.svg"
import IconMonitor from "../assets/icons/monitor.svg"
import IconValigetta from "../assets/icons/valigetta.svg"

const ICONS = {
  "monitor": IconMonitor,
  "azienda": IconAzienda,
  "bilanci": IconBilanci,
  "valigetta": IconValigetta
}

/**
 * 
 * @param {{ icon: "monitor" | "azienda" | "bilanci" | "valigetta", size: number }} props 
 */
const CustomIcon = ({ icon, size = 20, ...props }) => {
  return (
    <img
      {...props}
      src={ICONS[icon]}
      style={{ width: size, height: size }}
      alt={icon[0]}
    />
  )  
}

export default CustomIcon