import React, { useCallback } from "react";
import { getIn } from "formik";
import classNames from "classnames";
import omit from "lodash/omit";
import moment from "moment";
import DatePicker, { registerLocale } from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import it from "date-fns/locale/it";
import {
  Input,
  FormGroup,
  FormFeedback,
  Label,
  Col,
  InputGroup,
  InputGroupAddon,
  InputGroupText
} from "reactstrap";

import "./fields.css";

export const FieldRadio = ({ field, form, onClick, ...props }) => {
  const { value: checkboxValue, ...passProps } = props;
  return (
    <input
      type={"radio"}
      onClick={onClick}
      {...field}
      {...passProps}
      value={checkboxValue}
      checked={checkboxValue === field.value}
    />
  );
};

export const DateField = ({ label, field, form, style, dateFormat, ...props }) => {
  registerLocale("it", it);
  const m = moment(field.value, "YYYY-MM-DD");
  const selected = m.isValid() ? m.toDate() : null;

  return (
    <FormGroup className="datepicker-full-width">
      {label && <Label className="text-right">{label}</Label>}
      <div style={style}>
        <DatePicker
          autoComplete="off"
          className={classNames("form-control", {
            "is-invalid": form.touched[field.name] && !!form.errors[field.name]
          })}
          showYearDropdown
          locale={"it"}
          showMonthDropdown
          scrollableYearDropdown
          dropdownMode="select"
          yearDropdownItemNumber={80}
          name={field.name}
          selected={selected}
          onBlur={field.onBlur}
          onFocus={field.onFocus}
          dateFormat={dateFormat || 'dd/MM/yyyy'}
          onChange={(date, e) => {
            if (!date || date.toDateString() === "Invalid Date") {
              form.setFieldValue(field.name, "");
            } else {
              form.setFieldValue(field.name, moment(date).format("YYYY-MM-DD"));
            }
          }}
          {...props}
        />
        {form.touched[field.name] && form.errors[field.name] && (
          <FormFeedback className="d-block">
            {form.errors[field.name]}
          </FormFeedback>
        )}
      </div>
    </FormGroup>
  );
};

export const HourField = ({ label, field, form, style, ...props }) => {
  registerLocale("it", it);
  const m = moment(field.value, "HH:mm");
  const selected = m.isValid() ? m.toDate() : null;

  return (
    <FormGroup className="datepicker-full-width">
      {label && <Label className="text-right">{label}</Label>}
      <div style={style}>
        <DatePicker
          autoComplete="off"
          className={classNames("form-control", {
            "is-invalid": form.touched[field.name] && !!form.errors[field.name]
          })}
          locale={"it"}
          name={field.name}
          selected={selected}
          onBlur={field.onBlur}
          onFocus={field.onFocus}
          showTimeSelect
          showTimeSelectOnly
          timeIntervals={15}
          timeCaption="Time"
          dateFormat="H:mm"
          // dateFormat='dd/MM/yyyy'
          onChange={(date, e) => {
            if (!date || date.toDateString() === "Invalid Date") {
              form.setFieldValue(field.name, "");
            } else {
              form.setFieldValue(field.name, moment(date).format("HH:mm"));
            }
          }}
          {...props}
        />
        {form.touched[field.name] && form.errors[field.name] && (
          <FormFeedback className="d-block">
            {form.errors[field.name]}
          </FormFeedback>
        )}
      </div>
    </FormGroup>
  );
};

export const InputField = ({
  field,
  form: { touched, errors },
  label,
  addonText,
  rowLayout = false,
  labelClassName = "",
  formGroupClassName = "",
  ...props
}) => {
  const touch = getIn(touched, field.name);
  const error = getIn(errors, field.name);

  let inputElement = <Input {...field} {...props} invalid={touch && !!error} />;

  let feedbackElement = touch && error && (
    <FormFeedback className="d-block">{error}</FormFeedback>
  );

  if (addonText) {
    inputElement = (
      <InputGroup>
        {inputElement}
        <InputGroupAddon addonType="append">
          <InputGroupText className="bg-transparent">
            {addonText}
          </InputGroupText>
        </InputGroupAddon>
      </InputGroup>
    );
  }

  return (
    <FormGroup row={rowLayout} className={formGroupClassName}>
      {label && (
        <Label sm={rowLayout ? 4 : undefined} className={`text-left ${labelClassName}`}>
          {label}
        </Label>
      )}
      {rowLayout && (
        <Col sm={8}>
          {inputElement}
          {feedbackElement}
        </Col>
      )}
      {!rowLayout && (
        <React.Fragment>
          {inputElement}
          {feedbackElement}
        </React.Fragment>
      )}
    </FormGroup>
  );
};


export const PercentField = ({
  field,
  form: { touched, errors, setFieldValue },
  label,
  addonText,
  rowLayout = false,
  labelClassName = "",
  formGroupClassName = "",
  ...props
}) => {
  const touch = getIn(touched, field.name);
  const error = getIn(errors, field.name);

  let inputElement = <Input
    {...props}
    value={(parseFloat(field.value) * 100).toString()}
    onChange={e => setFieldValue(field.name, e.target.value / 100)}
    invalid={touch && !!error}
  />;

  let feedbackElement = touch && error && (
    <FormFeedback className="d-block">{error}</FormFeedback>
  );

  if (addonText) {
    inputElement = (
      <InputGroup>
        {inputElement}
        <InputGroupAddon addonType="append">
          <InputGroupText className="bg-transparent">
            {addonText}
          </InputGroupText>
        </InputGroupAddon>
      </InputGroup>
    );
  }

  return (
    <FormGroup row={rowLayout} className={formGroupClassName}>
      {label && (
        <Label sm={rowLayout ? 4 : undefined} className={`text-left ${labelClassName}`}>
          {label}
        </Label>
      )}
      {rowLayout && (
        <Col sm={8}>
          {inputElement}
          {feedbackElement}
        </Col>
      )}
      {!rowLayout && (
        <React.Fragment>
          {inputElement}
          {feedbackElement}
        </React.Fragment>
      )}
    </FormGroup>
  );
};

export const InputField2 = ({
  field,
  form: { touched, errors },
  label,
  addonText,
  rowLayout = false,
  ...props
}) => {
  const touch = getIn(touched, field.name);
  const error = getIn(errors, field.name);

  let inputElement = <Input {...field} {...props} invalid={touch && !!error} />;

  let feedbackElement = touch && error && (
    <FormFeedback className="d-block">{error}</FormFeedback>
  );

  if (addonText) {
    inputElement = (
      <InputGroup>
        {inputElement}
        <InputGroupAddon addonType="append">
          <InputGroupText className="bg-transparent">
            {addonText}
          </InputGroupText>
        </InputGroupAddon>
      </InputGroup>
    );
  }

  return (
    <FormGroup row={rowLayout}>
      {label && (
        <Label sm={rowLayout ? 4 : undefined} className="text-left">
          {label}
        </Label>
      )}
      {rowLayout && (
        <Col sm={8}>
          {inputElement}
          {feedbackElement}
        </Col>
      )}
      {!rowLayout && (
        <React.Fragment>
          {inputElement}
          {feedbackElement}
        </React.Fragment>
      )}
    </FormGroup>
  );
};

export const CheckboxField = ({ field, label, form, ...props }) => (
  <FormGroup check>
    <Label check>
      <Input
        {...props}
        type="checkbox"
        {...omit(field, "value")}
        checked={field.value}
      />{" "}
      {label}
    </Label>
  </FormGroup>
);


export const CheckboxFieldOptions = ({ field, label, form, trueValue = true, falseValue = false, trueLabel = "", falseLabel = "", ...props }) => (
  <FormGroup check>
    <Input
      {...props}
      type="checkbox"
      checked={field.value === trueValue}
      onClick={() => field.value === falseValue ? form.setFieldValue(field.name, trueValue) : form.setFieldValue(field.name, falseValue)}
    />{" "}
    <Label check>
      {field.value === trueValue ? trueLabel : falseLabel}
    </Label>
  </FormGroup>
);


export const ChangeCallbackInputField = ({ field, onChange, ...props }) => {
  const { onChange: onChangeOriginal, ...others } = field

  const onChangePatched = useCallback((e) => {
    onChangeOriginal(e)
    onChange && onChange(field.name, e.target.value)
  }, [field.name, onChange, onChangeOriginal])

  return <InputField {...props} field={{ ...others, onChange: onChangePatched }}></InputField>

}

export const FileField = ({ form, field, rowLayout = false, label, ...props }) => {
  const touch = getIn(form.touched, field.name);
  const error = getIn(form.errors, field.name);

  const handleChange = useCallback(e => {
    form.setFieldValue(field.name, e.target.files[0])
  }, [field.name, form])

  let inputElement = (
    <Input
      name={field.name}
      {...props}
      type="file"
      onChange={handleChange}
      invalid={touch && !!error}
    />
  )

  let feedbackElement = touch && error && (
    <FormFeedback className="d-block">{error}</FormFeedback>
  );

  return (
    <FormGroup row={rowLayout}>
      {label && (
        <Label sm={rowLayout ? 4 : undefined} className="text-left">
          {label}
        </Label>
      )}
      {rowLayout && (
        <Col sm={8}>
          {inputElement}
          {feedbackElement}
        </Col>
      )}
      {!rowLayout && (
        <React.Fragment>
          {inputElement}
          {feedbackElement}
        </React.Fragment>
      )}
    </FormGroup>
  )
}