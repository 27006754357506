import React, { useContext } from "react"
import { useRunRj, deps } from "react-rocketjump"
import { BilancioDetailState, BilancioSchemaState, SchemaIndicatoriState, IndicatoriBilancioState } from "../../localstate/bilanci"
import "./style.scss"
import { AziendaDetailState } from "../../localstate/aziende"
import { useAuthUser } from "use-eazy-auth"
import { ConfigContext } from "../../providers"
import FirstPage from "./FirstPage"
import BilancioPage from "./BilancioPage"
import IdAPage from "./IdAPage"
import "./IndicatorPage.scss"
import IdApPage from "./IdApPage"
import ICASPage from "./ICASPage"
import MixedIndicatorsPage from "./MixedIndicatorsPage"
import ISPIPage from "./ISPIPage"
import { ThresholdsState } from "../../components/IndicatorBar/localstate"
import Notes from "./Notes"
import ThresholdsPage from "./ThresholdsPage"
import { IndicatorStaticsState } from "../../localstate/indicatorStatics"
import keyBy from "lodash/keyBy"
import TCacPage from "./TCacPage"

const ReportBilancio = ({ bilancioId, className }) => {
  const [{ bilancio }] = useRunRj(BilancioDetailState, [bilancioId])
  const [{ bilancio: bilancioProiezione }] = useRunRj(BilancioDetailState, [deps.maybe(deps.maybeGet(bilancio, "bilancio_proiezione"))])
  const [{ azienda }] = useRunRj(AziendaDetailState, [deps.maybeGet(bilancio, "azienda")])
  const [{ schema }] = useRunRj(BilancioSchemaState, [])
  const [{ data: schemaIndicatori }] = useRunRj(SchemaIndicatoriState, [])
  const [{ data: indicatorsStatics }] = useRunRj(IndicatorStaticsState, [])
  const { user } = useAuthUser();
  const config = useContext(ConfigContext)

  const [{ list: indicatoriIda }] = useRunRj(IndicatoriBilancioState, [deps.maybeGet(bilancio, "id"), "IdA"])
  const [{ list: indicatoriIdap }] = useRunRj(IndicatoriBilancioState, [deps.maybeGet(bilancio, "id"), "IdAp"])
  const [{ list: indicatoriIcas }] = useRunRj(IndicatoriBilancioState, [deps.maybeGet(bilancio, "id"), "ICAS"])
  const [{ list: indicatoriLeve }] = useRunRj(IndicatoriBilancioState, [deps.maybeGet(bilancio, "id"), "LEVE"])
  const [{ list: indicatoriFcfo }] = useRunRj(IndicatoriBilancioState, [deps.maybeGet(bilancio, "id"), "FCFO"])
  const [{ list: indicatoriBep }] = useRunRj(IndicatoriBilancioState, [deps.maybeGet(bilancio, "id"), "BEP"])
  const [{ list: indicatoriIspi }] = useRunRj(IndicatoriBilancioState, [deps.maybeGet(bilancio, "id"), "ISPI"])
  const [{ list: indicatoriCac }] = useRunRj(IndicatoriBilancioState, [deps.maybeGet(bilancio, "id"), "RISANAMENTO"])
  const [{ data: thresholds }] = useRunRj(ThresholdsState, [deps.maybeGet(azienda, "settore")])

  if (!bilancio || !bilancioProiezione || !schema || !schemaIndicatori || !azienda || !thresholds) {
    return null
  }

  const props = {
    bilancio,
    azienda,
    commercialista: user.dati_commercialista,
    config,
    schema,
    schemaIndicatori,

    indicatoriIda,
    indicatoriIdap,
    indicatoriIcas,
    indicatoriLeve,
    indicatoriFcfo,
    indicatoriBep,
    indicatoriIspi,
    indicatoriCac,

    thresholds,
    indicatorsStatics: keyBy(indicatorsStatics, "codice"),

    className,
  }

  return (
    <div className={`ReportBilancio wrapper ${className}`}>
      <FirstPage {...props} />
      <BilancioPage title="Redazione del bilancio" {...props} />
      <Notes {...props} />
      <TCacPage {...props} />
      <IdAPage {...props} />
      {!bilancio.bilancio_chiusura && (
        <BilancioPage
          title="Bilancio prospettico a fine esercizio"
          {...props}
          bilancio={bilancioProiezione}
        />
      )}
      <IdApPage {...props} />
      <ICASPage {...props} />
      <MixedIndicatorsPage {...props} />
      <ISPIPage {...props} />
      <ThresholdsPage {...props} />
    </div>
  )
}

export default ReportBilancio