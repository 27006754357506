import React from "react"
import { putSpacesEvery000 } from "../../utils"
import get from "lodash/get"
import "./BilancioPage.scss"
import ReportHeader from "./Header"

const VoceBilancio = ({ schema, codice, depth, depthLimit, voci }) => {
  const path = codice.split(".")
  const schemaPath = path.join(".children.")
  const description = get(schema, schemaPath)
  const voce = voci.find(v => v.codice === codice)
  const valore = putSpacesEvery000(voce.rettifica || voce.valore) + " €"

  const childrenKeys = description.index || (description.children && Object.keys(description.children))

  return (
    <>
      <tr className={`voce-bilancio depth-${depth}`}>
        <td>{description.titolo}</td>
        {codice !== "3" && (<td>{valore}</td>)}
        {codice === "3" && (<td></td>)}
      </tr>
      {depth < depthLimit && childrenKeys && childrenKeys.map(childKey => (
        <VoceBilancio
          key={childKey}
          schema={schema}
          codice={(path.concat(childKey)).join(".")}
          depth={depth + 1}
          depthLimit={depthLimit}
          voci={voci}
        />
      ))}
    </>
  )
}

const RecapBilancio = ({ schema, voci, depthLimit = 2 }) => {
  return (
    <table className="recap-bilancio">
      <thead>
        <tr>
          <th>Voce bilancio</th>
          <th>Valore (€)</th>
        </tr>
      </thead>
      <tbody>
        {Object.keys(schema).map(topLevelKey => (
          <VoceBilancio
            key={topLevelKey}
            schema={schema}
            codice={topLevelKey}
            depth={1}
            depthLimit={depthLimit}
            voci={voci}
          />
        ))}
      </tbody>
    </table>
  )
}

const BilancioPage = props => {
  const { schema, bilancio, title } = props
  return (
    <div className="page bilancio-page">
      <ReportHeader {...props} />
      <h1>{title}</h1>
      <RecapBilancio schema={schema} voci={bilancio.voci} />
      {bilancio.note && (
        <>
          <h2>Annotazioni</h2>
          <p>{bilancio.note}</p>
        </>
      )}
    </div>
  )
}

export default BilancioPage