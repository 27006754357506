import React, { useContext, useState } from "react"
import Header from "../../components/Header"
import Menu from "../../components/Menu"
import Layout from "react-drawers"
import { Link } from "react-router-dom"
import { useRunRj } from "react-rocketjump"
import { AziendaDetailState } from "../../localstate/aziende"
import { AziendaContext } from "../../providers"
import { Modal } from "reactstrap"
import AziendaForm from "./AziendaForm"
import { format } from "date-fns"
import { FaAngleRight, FaTrashAlt } from "react-icons/fa"
import DeleteAziendaModal from "./DeleteAziendaModal"
import { useAuthUser } from "use-eazy-auth"
import DemoDeleteAziendaModal from "./DemoDeleteAziendaModal"

const Azienda = ({ match, history }) => {
  const { params } = match
  const { user } = useAuthUser()
  const isDemo = user.dati_licenza?.is_demo ?? false
  const [
    { azienda },
    { updateAzienda, deleteAzienda }
  ] = useRunRj(AziendaDetailState, [params.id])

  const { currentAzienda, setCurrentAzienda } = useContext(AziendaContext)

  const [editing, setEditing] = useState(false)
  const [deleting, setDeleting] = useState(false)
  const toggleEditing = () => setEditing(!editing)

  return (
    <Layout absolute>
      <Layout.Top>
        <Header></Header>
      </Layout.Top>
      <Layout.Left>
        <Menu></Menu>
      </Layout.Left>
      <Layout.Right></Layout.Right>

      <Layout.Content className="h-100 overflow-auto with-sticky-footer">
        <div className="sticky-header bg-light p-3">
          <div className="d-flex justify-content-between align-items-center w-100">
            {azienda && (
              <>
                <h4 className="m-0 d-inline-flex align-items-center">
                  <Link to={`/aziende`}>Aziende</Link>
                  <FaAngleRight className="mx-2" />
                  {azienda.ragione_sociale}
                </h4>
                {(!currentAzienda || currentAzienda.id !== azienda.id) && (
                  <button
                    className="btn btn-sm btn-primary"
                    onClick={() => setCurrentAzienda(azienda)}
                  >
                    Seleziona azienda
                  </button>
                )}
              </>
            )}
          </div>
        </div>

        <div className="p-3">
          <div className="card">
            <div className="card-header">Dati azienda</div>
            <div className="card-body">
              {azienda && (
                <div className="row">
                  <div className="col-8">
                    <dl className="row">
                      <dt className="col-sm-4">Ragione sociale</dt>
                      <dd className="col-sm-8">{azienda.ragione_sociale}</dd>

                      <dt className="col-sm-4">Settore</dt>
                      <dd className="col-sm-8">{azienda.settore}</dd>

                      <dt className="col-sm-4">Partita IVA</dt>
                      <dd className="col-sm-8">{azienda.piva}</dd>

                      <dt className="col-sm-4">Codice fiscale</dt>
                      <dd className="col-sm-8">{azienda.cf}</dd>

                      <dt className="col-sm-4">Data chiusura esercizio</dt>
                      <dd className="col-sm-8">
                        {format(
                          new Date(azienda.data_bilancio_chiusura),
                          "dd/MM"
                        )}
                      </dd>
                    </dl>
                  </div>
                  <div className="col-4 d-flex justify-content-center align-items-center">
                    {azienda.logo && (
                      <img
                        src={azienda.logo}
                        style={{
                          width: 150,
                          height: 150,
                          objectFit: "contain"
                        }}
                        alt="Logo aziendale"
                      />
                    )}
                  </div>
                </div>
              )}
            </div>
            <div className="card-footer text-center">
              <button
                className="btn btn-sm btn-outline-dark"
                onClick={toggleEditing}
              >
                Modifica dati
              </button>
              <button
                className="btn btn-sm btn-danger ml-2"
                onClick={() => setDeleting(true)}
              >
                <span className="d-inline-flex align-items-center">
                  <FaTrashAlt className="mr-1" />
                  <span>Elimina azienda</span>
                </span>
              </button>
            </div>
          </div>
        </div>

        <div className="sticky-footer bg-light border-top"></div>
      </Layout.Content>

      <Modal isOpen={editing} size="lg">
        <AziendaForm
          initialValues={azienda}
          title="Modifica dati azienda"
          save={values => updateAzienda.asPromise(azienda.id, values)}
          toggleModal={toggleEditing}
        ></AziendaForm>
      </Modal>

      {deleting && !isDemo && (
        <DeleteAziendaModal
          azienda={azienda}
          onConfirm={() => {
            deleteAzienda
              .onSuccess(() => {
                setDeleting(null)
                history.goBack()
              })
              .run(azienda.id)
          }}
          onCancel={() => setDeleting(false)}
        />
      )}
      {deleting && isDemo && (
        <DemoDeleteAziendaModal onCancel={() => setDeleting(false)} />
      )}
    </Layout>
  )
}

export default Azienda
