import React from "react"

const GiuffreAuth = ({ location }) => {
  return (
    <iframe
      src="/landing/index_20230516.html"
      title="4CheckImpresa"
      style={{ width: "100%", height: "100%" }}
    />
  )
}

export default GiuffreAuth
