// This should reflect colors defined in /styles/cassandra.scss

export const CARD_CLASSES = [
  { from: -Infinity, to: 0.25, className: "indicator-good", color: "#68d391" },
  { from: 0.25, to: 0.50, className: "indicator-ok", color: "#c4eb3b" },
  { from: 0.50, to: 0.75, className: "indicator-warn", color: "#f6e05e" },
  { from: 0.75, to: 1, className: "indicator-bad", color: "#ed8936" },
  { from: 1, to: Infinity, className: "indicator-worst", color: "#e53e3e" },
]

export const GIUFFRE_TOKEN_QUERY_PARAM = 'ticket'