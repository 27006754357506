import { ajax } from "rxjs/ajax";
import { map } from "rxjs/operators";

export const loginCall = credentials => {
  return ajax({
    url: `/api/token-auth/`,
    method: "post",
    body: credentials
  })
    .pipe(
      map(({ response }) => ({
        accessToken: response.token
        // refreshToken: response.refresh,
      }))
    )
    .toPromise();
};

// export const refreshTokenCall = (token) => {
//   return ajax({
//     url: `/api/token/refresh/`,
//     method: 'post',
//     body: { refresh: token }
//   }).pipe(map(({response}) => ({
//     accessToken: response.access,
//     refreshToken: token,
//   }))).toPromise()
// }

export const meCall = token => {
  return ajax({
    url: `/api/profile/`,
    method: "get",
    headers: {
      Authorization: `Token ${token}`
    }
  })
    .pipe(map(({ response }) => response))
    .toPromise();
};
