import React, { useContext } from "react"
import { NavLink, useHistory } from "react-router-dom"
import { AziendaContext } from "../providers"
import CustomIcon from "./CustomIcon"
import { BsPerson } from "react-icons/bs"
import { useAuthActions, useAuthState, useAuthUser } from "use-eazy-auth"

const MenuItem = ({ to, icon, children, exact = false }) => {
  return (
    <NavLink
      exact={exact}
      to={to}
      activeClassName="bg-selected text-white"
      className="list-group-item bg-primary text-white d-flex align-items-center justify-content-between"
    >
      {children}
      {icon}
    </NavLink>
  )
}

const MenuItemClick = ({ onClick, icon, children }) => {
  return (
    <span
      activeClassName="bg-selected text-white"
      className="list-group-item bg-primary text-white d-flex align-items-center justify-content-between nav-link"
      onClick={onClick}
    >
      {children}
      {icon}
    </span>
  )
}

export default function Menu() {
  const { currentAzienda, setCurrentAzienda } = useContext(AziendaContext)
  const { logout } = useAuthActions()
  const { authenticated } = useAuthState()
  const { user } = useAuthUser()
  const history = useHistory()

  return (
    <div className="w-100 bg-primary text-dark h-100 d-flex flex-column justify-content-start align-items-stretch">
      <ul className="list-group list-group-flush">
        <MenuItem to="/profile" icon={<BsPerson size={18} />} exact>
          Profilo
        </MenuItem>
        <MenuItem
          to="/aziende"
          icon={<CustomIcon icon="valigetta" size={18} />}
          exact
        >
          Aziende
        </MenuItem>

        {currentAzienda && (
          <>
            <div className="list-group-item bg-dark text-white d-flex align-items-center justify-content-between">
              <b>{currentAzienda.ragione_sociale}</b>
              <CustomIcon icon="azienda" size={18} />
            </div>
            <MenuItem
              to="/bilanci"
              icon={<CustomIcon icon="bilanci" size={18} />}
            >
              Bilanci
            </MenuItem>
          </>
        )}
      </ul>
      <div className="flex-1" />
      <ul className="list-group list-group-flush">
        {authenticated && user.is_staff && (
          <MenuItemClick
            onClick={e => {
              e.preventDefault()
              history.push("/")
              setCurrentAzienda(null)
              logout()
            }}
            className="nav-link"
            href="#logout"
          >
            Logout
          </MenuItemClick>
        )}
      </ul>
    </div>
  )
}
